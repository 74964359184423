import { sessionBrowserStorage } from "../Utils/BrowserStorage";

const searchParams = new URLSearchParams(window.location.search.substr(1));
const from = searchParams.get("from");

export function isLnln() {
  // return true;
  if (from === "lnln") return true;
  if (document.domain.match(/lnln/)) return true;
  return false;
}

export function setLnlnApp() {
  // called from initializeLnlnProcess@LnlnProcess.js
  try {
    sessionBrowserStorage.set("from-lnln-app", "true");
  } catch (e) {
    console.error("Session Storage Error:", e);
  }
}

function _getLnlnApp() {
  try {
    return sessionBrowserStorage.get("from-lnln-app");
  } catch (e) {
    console.error("Session Storage Error:", e);
  }
}

export function isLnlnApp() {
  return _getLnlnApp() === "true";
}
