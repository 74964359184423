import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Hub } from "aws-amplify";
import Guard from "../../../Utils/Guard";
import Heading1 from "../../atoms/headings/Heading1";
import Panel from "../../atoms/panels/Panel";
import FontP from "../../atoms/fonts/FontP";
import FullWidthButton from "../../atoms/buttons/ButtonFullWidth";
import PaymentCardUpdate from "../../organisms/payment/PaymentCardUpdate";
import BasicTemplate from "../../templates/BasicTemplate";
import { japaneseList } from "../../../Resources/japaneseList";
import Titles from "../../../Resources/Titles";

class UpdatePage extends Component {
  state = {
    isLoading: false,
    isCompleted: false,
  };

  handleClickCompleteButton = () => {
    this.props.history.push("/my-page");
  };
  handleClickRegisterButton = () => {
    this.setState({
      isLoading: true,
    });
  };

  handleCompleteRegisterCard = () => {
    this.setState({
      isLoading: false,
      isCompleted: true,
    });
  };

  showError = (message) => {
    this.setState({
      isLoading: false,
    });
    Hub.dispatch(
      "msg",
      {
        event: "open",
        data: { message: message, level: "error", autoHideDuration: 8000 },
      },
      "PaymentCard"
    );
  };

  render() {
    const renderResource = japaneseList.pages.payment.UpdatePage.render;
    const { isCompleted } = this.state;
    const cardSeq = this.props.match.params.cardSeq;

    const main = (
      <React.Fragment>
        <Heading1>{renderResource.Fragment.PrimaryHeading.j001}</Heading1>
        <Panel>
          {isCompleted ? (
            <React.Fragment>
              <Panel padding="l">
                <FontP align="center">
                  {
                    renderResource.Fragment.Container.Fragment.Row.Typography2
                      .j001
                  }
                </FontP>
              </Panel>
              <FullWidthButton onClick={this.handleClickCompleteButton}>
                {
                  renderResource.Fragment.Container.Fragment.Row.FullWidthButton
                    .j001
                }
              </FullWidthButton>
            </React.Fragment>
          ) : (
            <PaymentCardUpdate
              handleClickRegisterButton={this.handleClickRegisterButton}
              completeRegisterCard={this.handleCompleteRegisterCard}
              showError={this.showError}
              cardSeq={cardSeq}
            />
          )}
        </Panel>
      </React.Fragment>
    );
    return (
      <BasicTemplate
        title={Titles.cardUpdate}
        main={main}
        isLoading={this.state.isLoading}
      />
    );
  }
}

export default withRouter(Guard(UpdatePage));
