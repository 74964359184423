import React from "react";
import styled from "styled-components";
import { IconArrow } from "../icons/IconArrow";

interface AccordionProps {
  title: React.ReactNode;
  children: React.ReactNode;
  isDefaultOpen?: boolean;
}

const StyledIconArrow = styled(IconArrow)`
  &::after {
    border-color: ${({ theme }) => theme.color.secondary};
    border-width: 2px;
  }
  transition: transform 0.3s ease;
  flex-shrink: 0;
`;

const AccordionContainer = styled.details`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.space.normal};
  background: ${({ theme }) => theme.panel.background.color.accordion};
  text-align: left;

  &[open] ${StyledIconArrow} {
    transform: rotate(-90deg);
  }
`;

const AccordionHeader = styled.summary`
  width: 100%;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.panel.padding.s};
  background: ${({ theme }) => theme.panel.background.color.accordion};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.font.color.default};
  list-style: none;

  &::-webkit-details-marker {
    display: none;
  }

  > *:first-child {
    flex: 1;
    margin-right: ${({ theme }) => theme.space.short};
  }

  ${StyledIconArrow} {
    transform: rotate(90deg);
  }
`;

const AccordionContent = styled.div`
  padding: ${({ theme }) => theme.panel.padding.s};
  padding-top: 0;
`;

export const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  isDefaultOpen = false,
}) => {
  return (
    <AccordionContainer open={isDefaultOpen}>
      <AccordionHeader>
        {title}
        <StyledIconArrow size="8px" />
      </AccordionHeader>
      <AccordionContent>{children}</AccordionContent>
    </AccordionContainer>
  );
};
