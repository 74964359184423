import React, { Component, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import { Auth, Hub } from "aws-amplify";

import GTM from "./Utils/GoogleTagManager";
import client from "./Utils/GraphQLClient";
import { UpdateLastLoginTime } from "./Utils/GraphQLQueries";

import MessageBox from "./comp/organisms/global/MessageBox";
import { SuccessDialog } from "./comp/organisms/global/SuccessDialog";
import { detect } from "detect-browser";

/** new atomic design sample page */
import HomePage from "./comp/pages/HomePage";
import MyPage from "./comp/pages/MyPageNew";
import InsuranceCardPage from "./comp/pages/InsuranceCardPage";
import InsuranceCardConfirmPage from "./comp/pages/InsuranceCardConfirmPage";
import InsuranceCardCompletePage from "./comp/pages/InsuranceCardCompletePage";
import InsuranceCardErrPage from "./comp/pages/InsuranceCardErrPage";
import TermsOfServicePage from "./comp/pages/TermsOfServicePage";
import HospitalDetailPage from "./comp/pages/HospitalDetailPage";
import HospitalSearchPage from "./comp/pages/HospitalSearchPage";
import { SearchPlanHospitalsPage } from "./comp/pages/SearchPlanHospitalsPage";
import SearchResultsPage from "./comp/pages/SearchResultsPage";
import { InquirySignupSmsCompletedPage } from "./comp/pages/auth/InquirySignupSmsCompletedPage";
import InquiryPage from "./comp/pages/InquiryPage";
import AppointmentDetailPage from "./comp/pages/AppointmentDetailPage";
import OnlineChatPage from "./comp/pages/OnlineChatPage";
import { ThemeProvider } from "styled-components";
import * as theme from "./comp/theme";
import * as themeLnln from "./comp/themeLnln";
import { isLnln, isLnlnApp } from "./Utils/checkLnln";
import AuthenticationPage from "./comp/pages/auth/AuthenticationPage";
import ReservationPage from "./comp/pages/ReservationPage";
import ReservationConfirmPage from "./comp/pages/ReservationConfirmPage";
import ChangePasswordPage from "./comp/pages/auth/ChangePasswordPage";
import ConsumerPolicyPage from "./comp/pages/ConsumerPolicyPage";
import PrivacyPolicyPage from "./comp/pages/PrivacyPolicyPage";
import ApplicationPrivacyPolicyPage from "./comp/pages/ApplicationPrivacyPolicyPage";
import ProfileUpdatePage from "./comp/pages/profile/ProfileUpdatePage";
import CardInfoPage from "./comp/pages/payment/CardInfoPage";
import UpdateCardPage from "./comp/pages/payment/UpdatePage";
import RegisterCardPage from "./comp/pages/payment/RegisterPage";
import RegisteringCallBackPage from "./comp/pages/payment/RegisteringCallBackPage";
import SignOutPage from "./comp/pages/auth/SignOutPage";
import ReserveCompletePage from "./comp/pages/ReservationCompletePage";
import OnlineChatFinishPage from "./comp/pages/OnlineChatFinishPage";
import AddressPage from "./comp/pages/AddressPage";
import AddressDetailPage from "./comp/pages/AddressDetailPage";
import EmailPage from "./comp/pages/EmailPage";
import VerifyEmailPage from "./comp/pages/VerifyEmailPage";
import EmailCompletePage from "./comp/pages/EmailCompletePage";
import NotFound from "./comp/pages/NotFound";
import NotSupport from "./comp/pages/NotSupport";
import { NotificationListPage } from "./comp/pages/NotificationListPage";
import LnlnInfo from "./comp/pages/lnln/LnlnInfo";
import UnsupportedDevicePage from "./comp/pages/UnsupportedDevicePage";
import SiteContext from "./SiteContext";
import { withPolling } from "./contexts/PollingContext";
import PollingManager from "./comp/organisms/PollingManager";
import initializeLnlnProcess from "./LnlnProcess";
import NotAccess from "./comp/pages/NotAccess";
import * as Sentry from "@sentry/browser";
import {
  blockingUnsupportedDevice,
  getUnsupportedDeviceInfo,
} from "./Utils/UnsupportedDevice";
import UnsupportedDeviceAlert from "./comp/organisms/UnsupportedDeviceAlert";
import { GetMediaDeviceStatus, MediaDeviceStatus } from "./Utils/MediaDevice";
import OnlineChatStandbyPage from "./comp/pages/OnlineChatStandbyPage";
import { ReservationInterviewSheetPage } from "./comp/pages/ReservationInterviewSheetPage";
import { ReservationMedicineDeliveryPage } from "./comp/pages/ReservationMedicineDeliveryPage";
import { ReservationUploadFilePage } from "./comp/pages/ReservationUploadFilePage";
import { ReservationUploadFileErrorPage } from "./comp/pages/ReservationUploadFileErrorPage";
import { InterviewSheetAnswerUpdatePage } from "./comp/pages/InterviewSheetAnswerUpdatePage";
import { EnterpriseTopPage } from "./comp/pages/enterprise/EnterpriseTopPage";
import { EnterpriseAuthenticationPage } from "./comp/pages/enterprise/EnterpriseAuthenticationPage";
import { EnterpriseCompletePage } from "./comp/pages/enterprise/EnterpriseCompletePage";
import { EnterpriseConfirmPage } from "./comp/pages/enterprise/EnterpriseConfirmPage";
import { EnterpriseAuthorizationPage } from "./comp/pages/enterprise/EnterpriseAuthorizationPage";
import { SearchFromHospitalHistoriesPage } from "./comp/pages/SearchFromHospitalHistoriesPage";
import ErrorPage from "./comp/pages/auth/IvrErrorPage";
import CancellationPage from "./comp/pages/account/CancellationPage";
import CancellationErrorPage from "./comp/pages/account/CancellationErrorPage";
import SystemErrorPage from "./comp/pages/SystemErrorPage";
import RegisterCompleteModal from "./comp/pages/auth/RegisterCompleteModal";

Sentry.init({
  dsn: "https://4a43631cb3b7479f9d7d48f32933742d@sentry.io/2064605",
  attachStacktrace: true,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  enabled: process.env.REACT_APP_SENTRY_ENABLE === "true",
});

let isComplete = false;
const hubAuthListener = (data) => {
  // Apply user id to Sentry configuration.
  switch (data.payload.event) {
    case "signUp":
      break;
    case "autoSignIn":
      isComplete = true;
      Auth.currentUserPoolUser()
        .then((user) => {
          Sentry.setUser({
            id: user.username,
          });
        })
        .catch((err) => {});
      break;
    case "signOut":
      Sentry.captureMessage("signout", Sentry.Severity.Log);
      Sentry.setUser({
        id: null,
      });
      break;
    case "signIn_failure":
      break;
    case "signIn":
    case "configured":
      Auth.currentUserPoolUser()
        .then((user) => {
          Sentry.setUser({
            id: user.username,
          });
        })
        .catch((err) => {});
      break;
    default:
      break;
  }
};
Hub.listen("auth", hubAuthListener);

const UPDATELOGINTIME_INTERVAL = 10000;

const ThemeRenderer = ({
  component: Component,
  isNotSupport: IsNotSupport,
  ...props
}) => {
  const [blockStatus, setBlockStatus] = useState({
    isShowAlert: false,
    blockStartMessage: null,
  });

  useEffect(() => {
    const updateBlockStatus = async () => {
      if (!IsNotSupport) {
        const blockStatus = await blockingUnsupportedDevice(props);
        setBlockStatus(blockStatus);
      }
    };
    updateBlockStatus();
  }, []);
  const theme_ = isLnln() ? themeLnln : theme;
  let { isShowAlert, blockStartMessage } = blockStatus;
  return (
    <React.Fragment>
      <SiteContext.Consumer>
        {(value) =>
          value.ui === "lnln" ? (
            <ThemeProvider theme={themeLnln}>
              <React.Fragment>
                <Component {...props} />
                <PollingManager />
              </React.Fragment>
            </ThemeProvider>
          ) : (
            <ThemeProvider theme={theme_}>
              <React.Fragment>
                <Component {...props} />
                <PollingManager />
              </React.Fragment>
            </ThemeProvider>
          )
        }
      </SiteContext.Consumer>
      {isShowAlert && (
        <UnsupportedDeviceAlert
          blockStartMessage={blockStartMessage}
          props={props}
        />
      )}
    </React.Fragment>
  );
};

const DefaultPage = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(matchProps) => (
      <ThemeRenderer
        component={Component}
        isNotSupport={false}
        {...matchProps}
      />
    )}
  />
);

console.log(process.env.NODE_ENV);

function initializeSiteContext() {
  const searchParams = new URLSearchParams(window.location.search.substr(1));
  const from = searchParams.get("from");
  const token = searchParams.get("token");
  const clinic = searchParams.get("clinic");
  const isFromLnlnApp = from === "lnln" || isLnlnApp();
  return {
    ui: isFromLnlnApp ? "lnln" : null,
    token,
    lnln_clinicId: clinic,
    reserveData: null,
    reserveInput: null,
  };
}

const siteContext = initializeSiteContext();

if (siteContext.ui === "lnln") {
  initializeLnlnProcess(siteContext);
}

const currentBrowser = async function () {
  let { isBlock, alertArray } = await getUnsupportedDeviceInfo();
  let isWarningBrowser = false;
  if (!isBlock) {
    isWarningBrowser = alertArray.length > 0;
  }
  return isWarningBrowser;
};

class App extends Component {
  onlineChatStandByUrl = "/telemedicine/standby";
  intervalID = null;
  async componentDidMount() {
    this.updateLastLoginTime();
    this.intervalID = setInterval(() => {
      this.updateLastLoginTime();
    }, UPDATELOGINTIME_INTERVAL);
    // 時間経過でポーリング処理停止のためにIDを渡す
    const { addPollingId } = this.props.pollingContext;
    addPollingId("App", this.intervalID);
  }

  async updateLastLoginTime() {
    const { mediaDeviceStatus, mediaDeviceError } = await GetMediaDeviceStatus(
      window.location.pathname.startsWith(this.onlineChatStandByUrl)
    );

    const videoStandby = {
      isOpeningStandbyPage: window.location.pathname.startsWith(
        this.onlineChatStandByUrl
      ),
      isMediaDeviceBlocked: mediaDeviceStatus === MediaDeviceStatus.blocked,
      isMediaDeviceAccepted: mediaDeviceStatus === MediaDeviceStatus.accepted,
      isWarningBrowser: await currentBrowser(),
    };

    if (mediaDeviceError !== "") {
      videoStandby.lastMediaDeviceError = mediaDeviceError;
    }

    Auth.currentUserPoolUser()
      .then((user) => {
        client
          .mutate({
            mutation: UpdateLastLoginTime,
            variables: { videoStandby },
          })
          .catch((errors) => ({ errors }));
      })
      .catch((err) => {
        if (err.code === "UserNotFoundException") {
          Auth.signOut();
        }
      });
  }

  render() {
    const browser = detect();
    if (browser) {
      let version = parseInt(browser.version, 10);
      if (
        (browser.name === "firefox" && version < 45) ||
        (browser.name === "ie" && version < 11) ||
        (browser.name === "chrome" && version < 49) ||
        (browser.name === "safari" && version < 10) ||
        (browser.name === "ios" && version < 10) ||
        (browser.name === "edge" && version < 14)
      ) {
        return <ThemeRenderer component={NotSupport} isNotSupport={true} />;
      }
    }

    return (
      <SiteContext.Provider value={siteContext}>
        <BrowserRouter>
          <React.Fragment>
            <ScrollToTop />
            <Switch>
              {/** example atomic design signup page */}
              <DefaultPage
                exact
                path="/auth/inquiry-completed"
                component={InquirySignupSmsCompletedPage}
              />
              <DefaultPage
                exact
                key="/auth/:action"
                path="/auth/:action"
                component={AuthenticationPage}
              />
              <DefaultPage
                exact
                key="/login"
                path="/login"
                component={AuthenticationPage}
              />
              <DefaultPage
                exact
                path="/terms-of-service"
                component={TermsOfServicePage}
              />
              <DefaultPage exact path="/lnln-info" component={LnlnInfo} />
              <DefaultPage
                exact
                path="/online-chat"
                component={OnlineChatPage}
              />
              <DefaultPage exact path="/sign-out" component={SignOutPage} />
              <DefaultPage
                path="/my-page/password/change"
                component={ChangePasswordPage}
              />
              <DefaultPage exact path="/" component={HomePage} />
              <DefaultPage
                path="/appointment/:createTime"
                component={AppointmentDetailPage}
              />
              <DefaultPage
                exact
                path="/interview/update/:createTime"
                component={InterviewSheetAnswerUpdatePage}
              />
              <DefaultPage
                path="/hospital/:hospitalId"
                component={HospitalDetailPage}
              />
              <DefaultPage
                path="/search/histories"
                component={SearchFromHospitalHistoriesPage}
              />
              <DefaultPage path="/search/list" component={SearchResultsPage} />
              <DefaultPage
                path="/search/plan"
                component={SearchPlanHospitalsPage}
              />
              <DefaultPage path="/search" component={HospitalSearchPage} />
              <DefaultPage
                exact
                path="/reserve/input/:hospitalId/:menuId"
                component={ReservationPage}
              />
              <DefaultPage
                exact
                path="/reserve/interview/:hospitalId/:menuId"
                component={ReservationInterviewSheetPage}
              />
              <DefaultPage
                exact
                path="/reserve/medicine-delivery/:hospitalId/:menuId"
                component={ReservationMedicineDeliveryPage}
              />
              <DefaultPage
                exact
                path="/reserve/upload-file/:hospitalId/:menuId"
                component={ReservationUploadFilePage}
              />
              <DefaultPage
                exact
                path="/reserve/upload-file/error"
                component={ReservationUploadFileErrorPage}
              />
              <DefaultPage
                exact
                path="/reserve/confirm/:hospitalId/:menuId"
                component={ReservationConfirmPage}
              />
              <DefaultPage
                exact
                path="/reserve/complete"
                component={ReserveCompletePage}
              />
              <DefaultPage
                path="/reserve/input-paymentcard"
                component={RegisterCardPage}
              />
              <DefaultPage
                path="/consumer-policy"
                component={ConsumerPolicyPage}
              />
              <DefaultPage
                path="/application-privacy-policy"
                component={ApplicationPrivacyPolicyPage}
              />
              <DefaultPage
                path="/privacy-policy"
                component={PrivacyPolicyPage}
              />
              <DefaultPage exact path="/inquiry" component={InquiryPage} />
              <DefaultPage exact path="/my-page" component={MyPage} />
              <DefaultPage
                exact
                path="/my-page/insurance-card"
                component={InsuranceCardPage}
              />
              <DefaultPage
                exact
                path="/my-page/insurance-card/confirm"
                component={InsuranceCardConfirmPage}
              />
              <DefaultPage
                exact
                path="/my-page/insurance-card/complete"
                component={InsuranceCardCompletePage}
              />
              <DefaultPage
                exact
                path="/my-page/insurance-card/error"
                component={InsuranceCardErrPage}
              />

              <DefaultPage
                path="/my-page/profile/update"
                component={ProfileUpdatePage}
              />
              <DefaultPage
                path="/my-page/password/change"
                component={ChangePasswordPage}
              />
              <DefaultPage
                path="/notification-list"
                component={NotificationListPage}
              />
              <DefaultPage
                exact
                path="/my-page/payment/card-info"
                component={CardInfoPage}
              />
              <DefaultPage
                path="/my-page/payment/card-info/update/:cardSeq?"
                component={UpdateCardPage}
              />
              <DefaultPage
                path="/credit-card-callback"
                component={RegisteringCallBackPage}
              />
              <DefaultPage
                path="/telemedicine/chat"
                component={OnlineChatPage}
              />
              <DefaultPage
                path="/telemedicine/finish"
                component={OnlineChatFinishPage}
              />
              <DefaultPage
                path="/my-page/address/update"
                component={AddressPage}
              />
              <DefaultPage
                path="/my-page/address/detail"
                component={AddressDetailPage}
              />
              <DefaultPage
                path="/my-page/email/register"
                component={EmailPage}
              />
              <DefaultPage
                path="/my-page/email/verify"
                component={VerifyEmailPage}
              />
              <DefaultPage
                path="/my-page/email/complete"
                component={EmailCompletePage}
              />
              <DefaultPage exact path="/not-access" component={NotAccess} />
              <DefaultPage
                exact
                path="/unsupported-device-page"
                component={UnsupportedDevicePage}
              />

              <DefaultPage
                exact
                path="/telemedicine/standby"
                component={OnlineChatStandbyPage}
              />
              <DefaultPage
                exact
                path="/enterprise/top"
                component={EnterpriseTopPage}
              />
              <DefaultPage
                exact
                path="/enterprise/authenticate"
                component={EnterpriseAuthenticationPage}
              />
              <DefaultPage
                exact
                path="/enterprise/authorize"
                component={EnterpriseAuthorizationPage}
              />
              <DefaultPage
                exact
                path="/enterprise/confirm"
                component={EnterpriseConfirmPage}
              />
              <DefaultPage
                exact
                path="/enterprise/complete"
                component={EnterpriseCompletePage}
              />
              <DefaultPage exact path="/ivrError" component={ErrorPage} />
              <DefaultPage
                exact
                path="/cancellation-member"
                component={CancellationPage}
              />
              <DefaultPage
                exact
                path="/cancellation-member-error"
                component={CancellationErrorPage}
              />
              <DefaultPage
                exact
                path="/system-error"
                component={SystemErrorPage}
              />
              <DefaultPage component={NotFound} />
            </Switch>
            <MessageBox />
            <SuccessDialog />
            <GTM.RouteTracker />
            {isComplete && <RegisterCompleteModal />}
          </React.Fragment>
        </BrowserRouter>
      </SiteContext.Provider>
    );
  }
}

export default withPolling(App);
