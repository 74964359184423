export interface IBrowserStorage {
  set<T>(key: string, value: T): void;
  get<T>(key: string): T | null;
  remove(key: string): void;
  clear(): void;
}

export class BrowserStorage {
  private storage: Storage;

  constructor(storage: Storage) {
    if (!this.isStorageAvailable(storage)) {
      throw new Error(
        "ブラウザストレージが利用できません。プライベートモードを解除するか、ブラウザの設定でストレージの使用を許可してください。"
      );
    }
    this.storage = storage;
  }

  /**
   * ユーザー側で拒否が可能なため、ストレージが利用可能かチェック
   */
  private isStorageAvailable(storage: Storage): boolean {
    try {
      const testKey = "__storage_test__";
      storage.setItem(testKey, testKey);
      storage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  set<T>(key: string, value: T): void {
    try {
      const serializedValue = JSON.stringify(value);
      this.storage.setItem(key, serializedValue);
    } catch {
      try {
        const stringValue = String(value);
        this.storage.setItem(key, stringValue);
      } catch {
        return;
      }
    }
  }

  get<T>(key: string): T | null {
    const item = this.storage.getItem(key);
    if (!item) return null;

    try {
      return JSON.parse(item) as T;
    } catch {
      // parseが失敗した場合は、文字列をそのまま返す
      return item as unknown as T;
    }
  }

  remove(key: string): void {
    this.storage.removeItem(key);
  }

  clear(): void {
    this.storage.clear();
  }
}

export function createBrowserStorage(storage: Storage): IBrowserStorage | null {
  try {
    return new BrowserStorage(storage);
  } catch (e) {
    console.warn(`Storage initialization failed: ${e}`);
    return null;
  }
}

// 初期化済みのストレージインスタンス
export const localBrowserStorage = createBrowserStorage(window.localStorage);
export const sessionBrowserStorage = createBrowserStorage(
  window.sessionStorage
);
