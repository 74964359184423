const JapaneseTermsOfService = {
  heading: "利用規約",
  article1: {
    heading: "第１条（用語の定義）",
    text: {
      j001: "1. 本規約に用いられる用語のうち、主なものの定義は、本条に定めるとおりとします。",
      j002: "2. 本サービスの当事者や利用者に関する用語",
      j003: "(1) 登録医療施設：当社と本サービス（オンライン診療）の利用に係る契約を締結している医療施設",
      j004: "(2) 登録医師：登録医療施設に所属する医師。なお、本規約で医師という場合、特にことわりのないかぎり、医師法の定める医師と歯科医師法の定める歯科医師の双方を含みます。",
      j005: "(3) 登録薬局：当社と本サービス（オンライン服薬指導）の利用に係る契約を締結している薬局",
      j006: "(4) 登録薬剤師：登録薬局に所属する薬剤師",
      j007: "3. システムの機能に関する用語の定義",
      j008: "(1) 問診情報等：当社及び登録医療施設又は登録薬局が定める問診情報その他別途当社が定める事項",
      j009: "(2) 問診情報等送信機能：お客様が診療等に先立ち登録医療施設又は登録薬局に対して問診情報等を送信する機能",
      j010: "(3) 画像ファイル：登録医療施設又は登録薬局がアップロードする、検査結果、処方薬の添付文書その他のデータ（JPGファイル、PNGファイル及びPDFファイルがアップロード可能です。）",
      j011: "(4) 画像ファイル送受信機能：画像ファイルを登録医療施設又は登録薬局（登録医師又は登録薬剤師が主に利用しますが、登録医療施設又は登録薬局に勤務するその他の者が利用することがあります。）が、画像ファイルをアップロードし、お客様がその画像ファイルをダウンロードすることができる機能",
      j012: "(5) 画像データ：お客様がアップロードする画像データ（JPGファイル及びPNGファイルがアップロード可能です。）",
      j013: "(6) 健康保険証：お客様の加入する健康保険の被保険者証",
      j014: "(7) 保険証画像送受信機能：お客様が健康保険証の画像データをアップロードし、登録医療施設又は登録薬局においてその画像データをダウンロードすることができる機能",
      j015: "(8) 患者画像送受信機能：お客様が、診療等又はオンライン服薬指導に関連する画像データをアップロードし、登録医療施設又は登録薬局においてその画像データをダウンロードすることができる機能。この機能を用いてアップロードする画像データには、診療等又はオンライン服薬指導の実施それ自体に必要なものおよび登録医療施設又は登録薬局の求める診察券、おくすり手帳その他の書類などの画像データがあります。",
      j016: "(9) 映像送受信システム：オンライン診療等で用いられるリアルタイムの視覚及び聴覚の情報を含む情報通信手段",
      j017: "(10) 来院診療：登録医療施設において実施される診療",
      j018: "(11) 来院診療予約機能：来院診療の予約を本サービス上で行う機能",
    },
  },
  article2: {
    heading: "第２条 （総則）",
    text: {
      j001: "1. お客様が株式会社カラダメディカ（以下、「当社」といいます。）が提供するオンライン診療サービス（以下、「本サービス」といいます。本サービスは、オンライン診療サービス及びオンライン服薬指導サービスからなります。また、オンライン診療サービスには、お客様の受診する診療科に応じ、「CARADA オンライン診療」「ルナルナ オンライン診療」が含まれます。）を利用するに当たっては、利用規約（以下、「本規約」といいます。）をよくお読み頂き、ご理解の上で、当社が定める手続きに従い、本サービスの会員登録を申し込むものとします。本サービスの会員登録の申込みが行われた場合には、お客様は本規約及び別途当社が定める細則等（「個人情報の取り扱いについて」その他当社がお客様に遵守いただきたいものとして、本サービス上に掲示する方法で示すものを指します。以下、「本規約等」と総称します。）の内容を承諾したものとみなします。当社がお客様の会員登録を承認するときは、当社からお客様に対し、携帯電話のショートメッセージサービス（ＳＭＳ）その他の方法により認証番号を通知するものとし、お客様が当該認証番号を本サービスに入力し所定の会員登録手続を完了した時点で、お客様と当社との間で、本規約等の内容を含む本サービスの利用契約（以下、「本契約」といいます。）が成立するものとします。 ",
      j002: "2. お客様は会員登録に際し、以下に定める事由が真実かつ正確であることを表明し、当社がお客様の表明が虚偽又は不正確であると判断した場合その他当社がお客様の会員登録が不適切と判断した場合、当社はお客様の会員登録を拒否することができるものとします。",
      j003: "（1） お客様が未成年者である場合は、親権者等の法定代理人の同意を得ていること",
      j004: "（2） 過去に会員資格を抹消されていないこと",
      j005: "（3） 申込みの際に届出内容に、虚偽、誤記又は記入漏れがなく、かつ、二重登録その他の不正な申込みではないこと",
      j006: "3. 本サービス（オンライン診療）とは、登録医師によるお客様の診療行為等をサポートするための当社が提供する各種サービスをいい、以下のサービスを含むものとしますが、その仕様及び開始時期の詳細については別途当社が定めるところによるものとします。なお、本サービスはお客様が登録医療施設から保険診療の療養の給付（保険診療の療養の給付（健康保険法その他の法令に基づき診療が給付されるものをいい、以下、単に「保険診療」といいます。）を受けられる際にも使用できるものとします。",
      j007: "（1） お客様が登録医師からオンライン診療（法令、ガイドライン等により定義されるものをいいます。）及びオンライン医療相談（オンライン診療及びオンライン医療相談を、以下、「オンライン診療等」と総称します。また、来院診療予約機能を用いて予約された診療を含め、「診療等」と総称します。）を受けるための予約を行うシステムの提供 ",
      j008: "（2） 映像送受信システムの提供",
      j009: "（3） 問診情報等送信機能の提供",
      j010: "（4） 画像ファイル送受信機能の提供",
      j011: "（5） 保険証画像送受信機能の提供",
      j012: "（6） 患者画像送受信機能の提供",
      j013: "（7） 登録医療施設からお客様への処方箋又は処方薬の配送を、当社、登録医療施設又は登録患者の指定する配送業者（以下、「配送業者」といいます。）に委託するためのシステムの提供",
      j014: "（8） お客様が登録医療施設に支払う診療費用、調剤費用及び相談費用その他の請求権（以下、「診療費用等」と総称します。）の決済の代行並びにお客様が当社、登録医療施設又は配送業者に支払う配送料その他の請求権（以下、「配送料等」と総称します。）の決済の代行 ",
      j015: "（9） 来院診療予約機能の提供",
      j016: "4. 本サービス（オンライン服薬指導）とは、登録薬剤師によるお客様に対する服薬指導等をサポートするための当社が提供する各種サービスをいい、以下のサービスを含むものとしますが、その仕様及び開始及び終了の時期の詳細については別途当社が定めるところによるものとします。",
      j017: "（1） お客様が登録薬剤師からオンライン服薬指導（法令、ガイドライン等により定義されるオンライン服薬指導をいいます。以下同じです。）を受けるための予約を行うシステムの提供 ",
      j018: "（2） 映像送受信システムの提供",
      j019: "（3） 問診情報等送信機能の提供",
      j020: "（4） 画像ファイル送受信機能の提供",
      j021: "（5）保険証画像送受信機能の提供",
      j022: "（6）患者画像送受信機能の提供",
      j023: "（7）登録薬局又は登録薬剤師からお客様への処方薬の配送を、配送業者に委託するためのシステムの提供",
      j024: "（8）お客様が登録薬局に支払う調剤料、調剤基本料、薬剤服用歴管理指導料、薬剤料、医療材料費その他の請求権（以下、「調剤料等」と総称します。）の決済の代行並びに、配送料等の決済の代行",
      j025: "5. お客様は、本サービスを利用するに当たって、法令、条例、通知、要綱等（以下、「法令等」といいます。）及び本規約等に従わなければならないものとします。本規約と別途当社が定める細則等との間で齟齬があるときは、かかる細則等の定めが本規約の定めに優先するものとします。",
    },
  },
  article3: {
    heading:
      "第３条 （オンライン診療等・オンライン服薬指導の予約・問診情報等の送信）",
    text: {
      j001: "1. お客様は、当社が指定するウェブサイト（以下、「本サイト」といいます。）を通じて、診療等の実施を希望する登録医師又はオンライン服薬指導を実施する登録薬剤師を指定し、問診情報等を入力した上で、診療等又はオンライン服薬指導の予約の申込みをすることができます。",
      j002: "2. お客様は、お客様のお名前、電話番号、前項の定めに従って入力した問診情報等その他当社の定める事項が登録医療施設又は登録薬局に提供されることについて同意します。",
      j003: "3. お客様が第１項の定めに従ってオンライン診療等又はオンライン服薬指導の予約の申込みを行い、登録医療施設又は登録薬局に前項の事項が共有された時点で、お客様と登録医療施設との間でオンライン診療等に係る契約（オンライン服薬指導の場合、お客様と登録薬局との間のオンライン服薬指導を予約された時間に実施する合意）が成立するものとします。",
      j004: "4. 前各項にかかわらず、登録医療施設又は登録薬局はお客様の依頼を受けてお客様の診療等又はオンライン服薬指導の予約を行うことができるものとし、かかる場合には、登録医療施設又は登録薬局が本サイト上に当社が別途定める事項を入力した時点をもって、前項の契約又は合意が成立するものとします。",
      j005: "5. 登録医療施設又は登録薬局は、画像ファイルを、本サービスにアップロードすることにより、診療等又はオンライン服薬指導に際してお客様に検査結果、処方薬の添付文書等を閲覧させることができます。なお、当該画像ファイルは、アップロードから168時間経過後に削除されますので、それまでの間にダウンロード下さい。",
      j006: "6. 本サービスにより、お客様に対する保険診療が実施される場合（オンライン服薬指導の場合、薬の処方について健康保険が使用される場合）、診療等又はオンライン服薬指導に先立ち、保険証画像送受信機能を用いて健康保険証の画像データを本サービスにアップロードすることにより、保険証に記載されている健康保険番号等のお客様の健康保険についての情報を、登録医療施設又は登録薬局に通知するものとします。当該画像データは、診療等が予約された日の属する月の末日に削除されます。 ",
      j007: "7. お客様は、患者画像送受信機能を用い、画像データを本サービスにアップロードすることにより、診療等又はオンライン服薬指導の予約に紐づく形で、登録医療施設又は登録医師若しくは登録薬局又は登録薬剤師に対して画像データを閲覧・ダウンロードさせることができます。当該画像データは診療等又はオンライン服薬指導が予約された日の属する月の翌月末日に削除されます（診療等又はオンライン服薬指導がキャンセル等の理由により実施されなかった場合でも、画像データは予約されていた日の属する月の翌月末日まで削除されません。お客様が早期の削除を希望される場合、次項に定める削除機能を用いて、画像データを削除することができます。）。なお、診療等又はオンライン服薬指導実施後のアップロードは、実施された日から14日間の間のみ行うことができます。",
      j008: "8. お客様は保険証画像送受信機能又は患者画像送受信機能を用いてアップロードした画像データを、いつでも削除することができます。なお、お客様が画像データを削除したことは、登録医療施設及び登録医師又は登録薬局及び登録薬剤師が確認可能です。",
      j009: "9. （削除） ",
      j010: "10. お客様は、本サイトを通じて、別途当社が定める手続により予約の変更又はキャンセルをすることができます。登録医療施設又は登録薬局への連絡なしに、予約したオンライン診療等又はオンライン服薬指導の終了時刻までにオンライン診療等又はオンライン服薬指導が開始されない場合も、予約がキャンセルされたものとみなします（この場合のキャンセルを、「無断キャンセル」といいます。）。診療等のキャンセルに関する詳細は、本規約の末尾等、本サービス内に掲示する「キャンセルポリシー」をご覧ください。 ",
      j011: "11. 予約の際にお客様が指定した登録医師又は登録薬剤師がオンライン診療等又はオンライン服薬指導を実施できなくなった場合、登録医療施設又は登録医師若しくは登録薬局又は登録薬剤師の操作により、予約をキャンセルすることができます。",
    },
  },
  article4: {
    heading:
      "第４条 （映像送受信システムを利用したオンライン診療等・オンライン服薬指導及び費用等）",
    text: {
      j001: "1. 第３条第１項ないし第４項の定めに従って予約されたオンライン診療等又はオンライン服薬指導を受けるに当たって、お客様は本項各号に従い、当社が本サイトにて提供する映像送受信システムを用いることができます。",
      j002: "（1） お客様は予約した診療日時に限り、登録医師による診療又は登録薬剤師によるオンライン服薬指導を受けるために映像送受信システムを利用することができるものとします。",
      j003: "（2） お客様は、オンライン診療等又はオンライン服薬指導以外の目的のために、映像送受信システムを用いないものとします。",
      j004: "2. オンライン診療等に関する費用等",
      j005: "（1） お客様が登録医療施設に対して支払うべき診療費用等の金額は、登録医師がお客様に対して行ったオンライン診療等の内容に従って、登録医療施設が定めるものとします。当該診療費用等は、登録医療施設が金額を決定した後、お客様の個別の承諾を得ることなく、登録医療施設が定めた金額について第６条に定める方法により支払われることに、お客様は同意します。",
      j006: "（2） 前号の規定にかかわらず、登録医療施設がお客様に対して本サービスを用いて保険診療を実施する場合、登録医療施設は、厚生労働省の告示に係る診療報酬点数その他諸規則に基づき、お客様が登録医療施設に対して支払うべき診療費用等の金額を算定するものとします。当該診療費用等は、登録医療施設が金額を算定した後、お客様の個別の承諾を得ることなく、第６条に定める方法により支払われることに、お客様は同意します。ただし、第６条の定める方法により当社がお客様から代理受領し、登録医療施設に支払うのは、当該診療費用等のうち、お客様の一部負担金部分に限られ、その余の部分の健康保険組合等に対する請求その他一切の手続きは、全て登録医療施設がその責任で行うものとします。",
      j007: "（3） 本サイト上では登録医療施設が設定した場合、診療費用等の見込み金額（保険診療が実施される場合、一部負担金）が表示されますが、当該見込み金額はあくまで参考金額に過ぎず、前項に定める実際の診療費用等の金額と相違する場合がありうることにお客様は同意します。実際の診療費用等の金額については、お客様は、本サイト上で当該金額を確認することができます。お客様が診療費用等の金額に異議等がある場合には、直接、当該登録医療施設にお問い合わせ下さい。",
      j008: "（4） お客様が第３条第１０項に従ってオンライン診療等の予約の変更若しくはキャンセルをする場合、又は、予約したオンライン診療等の終了時刻までにオンライン診療等が開始されない場合には、登録医療施設は、登録医療施設が別途定めるところにより、前項に定める診療費用等の見込み金額の全部又は一部をキャンセル料として領収することができるものとします（登録医療施設に対して支払うべきこのキャンセル料を、「キャンセル料」といいます。）。かかるキャンセル料の支払いは、第６条に定める方法により、行われるものとします。ただし、本項のキャンセル料は、予約された診療が保険診療である場合は発生しないものとします。 ",
      j009: "(5)  お客様は、オンライン診療の結果登録医療施設又は登録医師が処方箋又は処方薬を処方する必要があると判断した場合、処方箋又は処方薬の交付は、処方箋又は処方薬を配送する方法によってのみ行われることに同意します。この場合、当該配送は、配送業者が行うものとします。その詳細は、本条第４項に定めるとおりです。",
      j010: "3. オンライン服薬指導に関する費用等",
      j011: "（1） お客様が登録薬局に対して支払うべき調剤料等の金額は、登録薬剤師がお客様に対して行ったオンライン服薬指導の内容に従って、登録薬局が定めるものとします。当該調剤料等は、登録薬局が金額を決定した後、お客様の個別の承諾を得ることなく、登録薬局が定めた金額について第６条に定める方法により支払われることに、お客様は同意します。",
      j012: "（2） 前項の規定にかかわらず、登録薬局がお客様に対して行う処方が、健康保険を用いて行われるとき、登録薬局は、厚生労働省の告示に係る診療報酬点数その他諸規則に基づき、お客様が登録薬局に対して支払うべき調剤料等の金額を算定するものとします。当該調剤料等は、登録薬局が金額を算定した後、お客様の個別の承諾を得ることなく、第６条に定める方法により支払われることに、お客様は同意します。ただし、第６条の定める方法により当社がお客様から代理受領し、登録薬局に支払うのは、当該調剤料等のうち、お客様の一部負担金部分に限られ、その余の部分の健康保険組合等に対する請求その他一切の手続きは、全て登録薬局がその責任で行うものとします。",
      j013: "（3） 本サイト上では登録薬局が設定した場合、調剤技術・薬剤管理料の見込み金額（健康保険が用いられる場合は、一部負担金）が表示されますが、当該見込み金額はあくまで参考金額に過ぎず、前項に定める実際の調剤技術・薬剤管理料の金額と相違する場合がありうることにお客様は同意します。実際の調剤技術・薬剤管理料の金額については、お客様は、本サイト上で当該金額を確認することができます。お客様が調剤技術・薬剤管理料及び薬剤料の金額に異議等がある場合には、直接、当該登録薬局にお問い合わせ下さい。",
      j014: "（4） お客様は、調剤済み薬剤の交付は、調剤済み薬剤を配送する方法によってのみ行われることに同意します。この場合、当該配送は、配送業者が行うものとします。その詳細は、本条第４項に定めるとおりです。",
      j015: "4. 処方箋・処方薬の配送及びその費用等",
      j016: "（1） お客様は、処方箋又は処方薬の配送先住所として、原則として自宅住所を登録するものとします。お客様は、配送先住所として自宅以外の住所を登録した場合、お客様以外の第三者が処方箋や処方薬を開封する等お客様に損害が生じたとしても、当社は一切責任を負わないことに同意します。",
      j017: "（2） 前項に規定する処方箋又は処方薬の配送は、当社、登録医療施設、登録薬局又は配送業者が別途定めるところにより実施するものとします。お客様は、受取人がお客様本人に限定されない方法で当該配送が行われ、お客様以外の者が当該配送の受取人となる可能性があることを了承し、これに異議がないことを確認します。 ",
      j018: "（3） お客様が支払うべき配送料等の金額並びに処方せん及び処方薬の梱包に要する費用（以下「梱包資材料」といいます。）は、配送する処方箋若しくは処方薬又は配送方法に応じて、当社、登録医療施設、登録薬局又は配送業者が定めるものとします。当該配送料等及び梱包資材料は、当社、登録医療施設、登録薬局又は配送業者が定めた金額について第６条に定める方法により支払われることに、お客様は同意します。 ",
      j019: "（4） 本項に規定する処方箋又は処方薬の配送に当たっては、お客様は、当社に対して、第６条に定める方法により、当社が配送手配に関連して用いた費用として別途定める金額（以下、「配送手配料」といいます。）を支払うものとします。 ",
      j020: "（5） 本サイト上では配送料等に梱包資材料及び配送手配料の金額を加算した配送金額が表示されます。",
    },
  },
  article5: {
    heading: "第５条 （来院診療予約機能）",
    text: {
      j001: "1. お客様は、この機能の利用を選択した登録医療施設における来院診療について、この機能を用いて来院診療の予約をすることができます。",
      j002: "2. お客様が、本サイトを通じて、登録医療施設に来院診療の予約の申込みをした場合、お客様の氏名、電話番号その他別途当社が定める事項が、当社から登録医療施設に提供されます。",
      j003: "3. お客様が来院診療の予約の申込みを行った時点で、登録医療施設の特段の行為を要せず、来院診療についての予約が成立します。",
      j004: "4. 当社は、予約が成立した場合、直ちに予約の成立を登録医療施設に対して通知します。",
      j005: "5. 来院診療の予約を行う際、オンライン診療等の場合と同様に、第３条第１項の定めに基づき問診情報等をご記入いただきます。ご入力いただいた問診情報等は、第３条第２項の定めに基づき、登録医療施設に送信されます。",
      j006: "6. お客様の予約にあたって、本サイトを通じた来院診療の予約と、その他の方法による来院診療の間で予約の重複が発生した場合、お客様と登録医療施設との間で問題を解決するものとし、その重複の発生が本サービスの不具合等（当社の故意又は重過失に基づくものに限ります。）に基づくものでない限り、当社は一切の責任を負いません。",
    },
  },
  article6: {
    heading: "第６条 （決済方法）",
    text: {
      j001: "1. お客様は、第４条第２項第１号に定める診療費用等債権（第４条第２項第４号に定めるキャンセル料を含みます。以下本条において同じです。）について、当社がそれらを登録医療施設の代理として受領する権限を有していること、第４条第３項第１号に定める調剤料等債権について、当社がそれらを登録薬局の代理として受領する権限を有していること、第４条第４項第３号に定める配送料等債権について、当社がそれらを配送業者の代理として受領する権限を有していること並びに第４条第４項第４号に定める配送手配料債権とあわせて当該診療費用等、調材料等及び配送料等を当社又は当社が指定する第三者に対して支払うことに同意します。 ",
      j002: "2. 前項の支払については、お客様が予め登録した、クレジットカードを利用した決済を行うこととします。お客様は、クレジットカードの登録情報に変更又は誤りがあった場合、直ちにこれを修正しなければならないものとし、お客様が登録したクレジットカードによる決済が１回でもできなかった場合、当社又は当社が指定する第三者は、お客様による本サービスの利用を停止することができるものとします。",
      j003: "3. 当社又は当社が指定する第三者は、診療費用等（オンライン服薬指導の場合は調剤料等）について、登録医療施設又は登録薬局が第３条第２項第１号又は同第3項第1号（保険適用の場合は同第２項第２号又は同第３項第２号）に従い定めた金額を、前項に定める方法により決済するものとします。第４条第２項第３号又は同第３項第３号に定める場合のほか、お客様が診療費用等（オンライン服薬指導の場合は調剤料等）の額に異議等がある場合も、当社又は当社が指定する第三者はかかる決済を停止又は中止することなく、登録医療施設又は登録薬局が定めた金額について決済を行うことができること、及び、診療費用等（オンライン服薬指導の場合は調剤料等）その他の請求権に係る紛争については、第１２条第２項の定めに従い、お客様と登録医療施設又は登録薬局との間で解決することについて、お客様は同意します。",
      j004: "4. 当社又は当社が指定する第三者は、配送料等、梱包資材料及び配送手配料について、当社、登録医療施設、登録薬局又は配送業者が第４条第４項第３号に従い定めた金額及び当社が第４条第４項第４号に従い定めた金額を、それぞれ本条第２項に定める方法により決済するものとします。第４条第４項第５号に定める場合のほか、お客様が配送金額に異議等がある場合も、当社又は当社が指定する第三者はかかる決済を停止又は中止することなく、第４条第４項第４号及び同第５号に従い定められた金額について決済を行うことができること並びに配送金額その他の請求権に係る紛争については、第１２条第４項の定めに従い、お客様と配送業者との間で解決することについて、お客様は同意します。 ",
      j005: "5. 当社は、お客様から受領した個人情報（カード名義、クレジットカード番号、有効期限、セキュリティコード等のクレジットカード情報、メールアドレス、携帯電話番号、その他本サービスの利用状況に関する情報等）をクレジットカード決済における不正利用防止・検知（３Dセキュア等）のため、お客様がご利用のカード発行会社に対しても開示・提供するものとし、本サービスのご利用に当たり、お客様は当該開示・提供について同意します。また、お客様が利用されているカード発行会社が外国にある場合、これらの個人情報は当該発行会社が所属する国に移転される場合があります。当社では、お客様から収集した情報からは、ご利用のカード発行会社及び当該会社が所在する国を特定することができないため、以下の個人情報保護措置に関する情報を把握して、ご提供することはできません。",
      j006: "・提供先が所在する外国の名称",
      j007: "・当該国の個人情報保護制度に関する情報",
      j008: "・発行会社の個人情報保護の措置",
      j009: "なお、個人情報保護委員会のホームページ（<a href='https://www.ppc.go.jp/' target='_blank' rel='noopener'>https://www.ppc.go.jp/</a>）では、各国における個人情報保護制度に関する情報について掲載されています。",
    },
  },
  article7: {
    heading: "第７条 （通信料）",
    text: {
      j001: "本サービスのご利用には別途通信料がかかる場合があります。かかる通信料についてはお客様が負担するものとします。",
    },
  },
  article8: {
    heading: "第８条 （権利帰属）",
    text: {
      j001: "本サービスに関する知的財産権は全て当社又は当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。",
    },
  },
  article9: {
    heading: "第９条 （パスワード等の管理）",
    text: {
      j001: "1. お客様は、当社がお客様に付与した認証番号、又は本サービスで用いるパスワードの使用を第三者に許諾をすること、開示・漏洩することその他お客様以外の第三者がお客様の会員資格を用いて本サービスを利用することを可能にする一切の行為を行ってはならず、お客様の認証番号及びパスワードを厳格に管理するものとします。",
      j002: "2. お客様の認証番号又はパスワードを用いたアクセスがなされた場合等、お客様の会員資格を用いたアクセスがなされた場合は、当社は、当該アクセスをお客様によるアクセスとみなして取り扱います｡第三者がお客様の会員資格を用いたアクセスを行ったことにより、当社又は第三者に損害が発生した場合、お客様は当該損害を補償する責任を負い、当社は一切責任を負わないものとします｡",
    },
  },
  article10: {
    heading: "第１０条 （個人情報の取扱い等）",
    text: {
      j001: "1. 当社は、登録医療施設又は登録薬局の委託を受け、登録医師又は登録薬剤師が画像ファイル送受信機能を用いてアップロードした、お客様についての画像ファイルを保存するものとします。この画像ファイルは、アップロードから168時間経過後に自動的に削除されます。",
      j002: "2. 当社は、お客様の委託を受け、お客様が保険証画像送受信機能又は患者画像送受信機能を用いてアップロードした画像データを保存するものとします。この画像データは、下記に定める時期に自動的に削除されます。",
      j003: "① 保険証画像送受信機能を用いてアップロードされた画像データ",
      j004: "診療等又はオンライン服薬指導が予約された日の属する月の末日",
      j005: "② 患者画像送受信機能を用いてアップロードされた画像データ",
      j006: "診療等又はオンライン服薬指導が予約された日の属する月の翌月末日。なお、画像データの紐づいた予約がキャンセル等の理由により実施されなかった場合でも、画像データは診療等又はオンライン服薬指導の実施を予約していた日の属する月の翌月末日まで削除されません。お客様が早期の削除を希望される場合は、次号に定める削除機能を用いて、画像データをいつでも削除することができます。",
      j007: "③ その他、お客様は患者画像送受信機能を用いてアップロードした画像データを、いつでも削除することができます。なお、お客様が画像データを削除したことは、登録医療施設及び登録医師並びに登録薬局及び登録薬剤師が確認可能です。",
      j008: "3. 当社は、登録医療施設又は登録医師が処方箋又は処方薬の処方が必要と認めた場合及び登録薬局及び登録薬剤師が処方薬を送付する場合、当該処方箋又は処方薬を送付する登録医療施設又は登録医師、登録薬局又は登録薬剤師及び配送業者に対して、当該送付及び配送に必要な範囲で、お客様の氏名、配送先住所、電話番号、処方内容等の情報を提供するものとし、お客様は当該提供に同意します。",
      j009: "4. 前各項のほか、当社が本サービスで取得する個人情報については、別途当社が定める「個人情報の取り扱いについて」、個人情報の保護に関する法律その他の法令を遵守して取り扱うものとします。",
    },
  },
  article11: {
    heading: "第１０条の２（問診情報の取扱い）",
    text: {
      j001: "1. お客様が入力し、登録医療施設に送信される問診情報等の取り扱いについては、「個人情報の取り扱いについて」に記載されるとおりとします。",
      j002: "2. ご入力いただいた問診情報等は、前項に基づく利用のほか、匿名加工を施した上で、利用することがあります。その詳細については、「個人情報の取り扱いについて （４）匿名加工情報の利用」に記載されるとおりとします。",
      j003: "3. 本条に定めるほか、問診情報等については、別途当社の定める「個人情報の取り扱いについて」、個人情報の保護に関する法律その他の法令を遵守して取り扱うものとします。",
    },
  },
  article12: {
    heading: "第１０条の３（その他情報の利用）",
    text: {
      j001: "前条及び前々条に定める情報のほか、お客様のほか、登録医療施設、登録医師、登録薬局又は登録薬剤師が本サービスを利用することを通じて、当社が取得した情報（本サービスに登録された情報や、登録医療施設、登録医師、登録薬局又は登録薬剤師による利用履歴、不具合情報等を指しますが、これらに限りません。以下、「情報等」といいます。）については、当社が本サービスの保守、本サービスの運用、本サービス内容の改善、マーケティングその他の目的に利用することができるものとします。また、これらの情報等に関わる知的財産権は当社が保有するものとします。ただし、情報等が個人情報に該当する場合は、前条及び前々条の定めに反しない態様での利用を行います。",
    },
  },
  article13: {
    heading: "第１０条の４（登録医療施設又は登録薬局に対するサポート）",
    text: {
      j001: "当社は、登録医療施設及び登録薬局による本サービスの利用にあたり、それらの者（登録医師や登録薬剤師を含みますが、これに限りません。）による本サービスの円滑な利用をサポートするために、下記のサービスを登録医療施設及び登録薬局に対し提供しています。その際、当社においてお客様に関する情報等（前条に定めるものをいいます。）が閲覧可能な状態になりますが、そちらについては、本条に定めるサポートに必要な範囲を超えて利用することはありません。",
      j002: "記",
      j003: "当社が登録医療施設又は登録薬局に対して実施するサポートの一環としての、当社が別途用意する遠隔操作システムを用い、登録医療施設及び登録薬局が本サービスの利用のために用いている端末を直接操作することで操作をサポートするサービス",
    },
  },
  article14: {
    heading: "第１０条の５（オンライン資格確認）",
    text: {
      j001: "本サービスにおいては、お客様のマイナンバーカードのICチップまたは健康保険証の記号番号等により、受診する医療機関等がオンラインでお客様の資格情報の確認ができるオンライン資格確認を利用することができます。オンライン資格確認のためには、第三者による外部サイト（以下、「オンライン資格確認サービス」といいます。）を利用します。当社はオンライン資格確認サービスについて一切の責任を負わず、お客様は、当該サービスについての運用状況や利用方法等に関する問い合わせ、苦情等について、直接運営者に行うものとします。",
    },
  },
  article15: {
    heading: "第１１条 （誓約事項）",
    text: {
      j001: "お客様は、以下の事項につき誓約します。",
      j002: "（1） 当社が合理的と判断する場合には、登録医療施設又は登録薬局がお客様に対して行うオンライン診療等及びオンライン服薬指導の対象疾病、その他一切の本サービスの利用方法等に関する事項について制限を付すことができることにつき同意し、かかる場合には当該制限を遵守すること",
      j003: "（2） 本サービスを登録医師からのオンライン診療等を受けること又は登録薬剤師からのオンライン服薬指導を受けること以外の目的で利用しないこと",
      j004: "（3） 法令等、本規約等その他本サービスの性質上許容される範囲を超えた行為を登録医師又は登録薬剤師に求めないこと",
      j005: "（4） 本サービスの利用に当たって用いる端末等について適切なセキュリティを構築すること",
      j006: "（5） 無断キャンセルや短期間における複数回の予約キャンセル等、登録医療施設又は登録薬局の業務の妨げになる態様での予約のキャンセルを行わないこと ",
      j007: "（6） 本サービスを日本国外から利用しないこと",
      j008: "（7） その他、当社からの要請があった場合には、当該要請に従うこと",
    },
  },
  article16: {
    heading: "第１２条 （免責）",
    text: {
      j001: "1. お客様に対してオンライン診療等又はオンライン服薬指導を行うのは登録医師若しくは登録医療施設又は登録薬局若しくは登録薬剤師であり、当社は、これらの者の行為についていかなる責任も負うものではありません。また、当社は、これらの者が本サービスを通じて提供する情報について、内容の正確性、有用性、完全性について保証するものではありません。",
      j002: "2. お客様と登録医師又は登録医療施設との間の診療等に係る契約（第４条に定める診療費用等、その見込み金額及びキャンセル料を含みます。）又はお客様と登録薬局又は登録薬剤師との間のオンライン服薬指導に係る契約（第４条に定める調材料等、オンライン服薬指導の事前準備、特定処方箋の送付その他のオンライン服薬指導の実施手順の履行を含みます。）に関連して生じた問い合わせ、苦情、請求、紛争等については、お客様自身の費用と責任においてお客様と登録医師若しくは登録医療施設又は登録薬局若しくは登録薬剤師との間で解決するものとし、当社は、これらの紛争等については、当該紛争が本サービスの不具合等（当該不具合が当社の故意又は重過失に起因する場合に限ります。）に起因する場合を除き、一切責任を負うことなく、また、その解決に関与する義務を負いません。",
      j003: "3. 問診情報等送信機能、画像ファイル送受信機能、保険証画像送受信機能及び患者画像送受信機能の利用に関して何らかの紛争が発生した場合であっても、お客様と登録医療施設若しくは登録医師又は登録薬局若しくは登録薬剤師との間で解決するものとし、当社は、これらの紛争等については、当該紛争が本サービスの不具合等（当該不具合が当社の故意又は重過失に起因する場合に限ります。）に起因する場合を除き、一切責任を負うことなく、また、その解決に関与する義務を負いません。",
      j004: "4. お客様に対する処方箋又は処方薬の配送は、登録医療施設若しくは登録医師又は登録薬局若しくは登録薬剤師及び配送業者によって行われるものであり、当社は、登録医療施設若しくは登録医師又は登録薬局若しくは登録薬剤師及び配送業者の行為についていかなる責任も負うものではありません。",
      j005: "5. お客様に対する処方箋又は処方薬の配送（第４条第４項第３号に定める配送金額や、お客様が登録した配送先住所とは異なる住所に配送された場合やお客様以外の者が受取人となった場合の取扱いを含みます。）に関連して生じた問い合わせ、苦情、請求、紛争等については、お客様自身の費用と責任においてお客様と登録医療施設若しくは登録医師又は登録薬局若しくは登録薬剤師及び配送業者との間で解決するものとし、当社は、これらの紛争等については、当該紛争が本サービスの不具合等（当該不具合が当社の故意又は重過失に起因する場合に限ります。）に起因する場合を除き、一切責任を負うことなく、また、その解決に関与する義務を負いません。",
      j006: "6. お客様及び登録医療施設又は登録薬局は、それぞれ、自己の責任と費用において本サービスの全部又は一部の利用のために必要なインターネット接続環境、所定のスペックを有する通信機器等を準備し、設定する必要があります。当社は、本サービス利用中のお客様又は登録医師若しくは登録医療施設若しくは登録薬局若しくは登録薬剤師が使用する通信機器等が正常に作動すること、当該通信機器間で正常かつ安定した通信が行われることを保証しません。",
      j007: "7. 当社は、本サービスに関して、次の各号について保証するものではありません。",
      j008: "（1） 当社の故意又は重過失なしに、電気通信回線等の障害によって本サービスの中断、不具合、エラー等が生じないこと",
      j009: "（2） 当社の故意又は重過失なしに、不正アクセス、ハッキング等によって本サービス、本サイトに対する毀損、改ざん又は本サービス、本サイトの中断、不具合、エラー等が生じないこと",
      j010: "（3） その他、本サービスの完全性、有用性、利用可能性、確実性、品質の満足性又は特定の目的への適合性に関しては、一切保証するものではありません。",
    },
  },
  article17: {
    heading: "第１３条 （サービスの変更、停止、終了等）",
    text: {
      j001: "1. 当社が本サービスの内容を変更する場合（軽微な変更及び本サービスの内容に直接関係しない変更（本サービスの内容に直接関係する変更とは、機能の追加・改廃を指します。）はこの「変更」に含みません。）を行う場合、変更内容の概要を事前にお客様に通知するものとします。",
      j002: "2. 当社は、以下のいずれかの事由に該当する場合、お客様への事前の通知なく、本サービスを停止又は終了することができます。ただし、第１号に定める保守、更新等のうち、定期的なシステム保守に関しては、可能な限り、本サービス上での掲示その他の手段により、事前の通知をするよう努めることとし、その他の事由により本サービスの提供を停止する場合も、可能な限り速やかに停止すること及びその理由を通知するよう努めるものとします。また、いずれの場合も、本サービスの提供再開時期を、可能な限り速やかに通知します。",
      j003: "（1） 本サービス運営のためのシステムの保守、更新等を定期的又は臨時に行う場合",
      j004: "（2） ウィルス被害、火災、停電、天災地変などの不可抗力により、本サービスの提供が困難な場合",
      j005: "（3） 第三者の故意又は過失による行為によって発生した本サービスの不具合について対策を講じる必要がある場合",
      j006: "（4） 法令等の改正、成立により、本サービスの従前の状態での運営が困難となり、適法性維持のための検討や、適法性確保のための修正等の事由により、サービスの停止が必要となった場合",
      j007: "（5） 本サービスの提供に必要な、第三者が提供するサービス（本サービスの運営に用いるサーバーサービスを想定していますが、それに限りません。）の提供停止 ",
      j008: "（6） その他、当社が本サービスの提供の停止・終了が必要と判断した場合",
      j009: "3. 当社は、前項により本サービスの提供が停止されている場合で、かつ、以下のいずれかの事由に該当する場合、本サービスを終了することができます。",
      j010: "（1） ウィルス被害、火災、停電、天災地変などの不可抗力により、本サービスの提供継続が困難と当社が判断した場合",
      j011: "（2） 第三者の行為（不作為を含みます。）によって発生した本サービスの不具合により、本サービスの提供が困難となった場合",
      j012: "（3） 本サービスの提供に必要な、第三者が提供するサービス（本サービスの運営に用いるサーバーサービスを想定していますが、それに限りません。）の提供停止・終了により、本サービスの提供が困難になった場合",
      j013: "（4） 法令等の改正、成立により本サービスの運営が困難となった場合",
      j014: "（5） その他、前各号に定める事由に準ずる事由が発生し、当社が本サービスの提供継続が困難と判断した場合",
      j015: "4. 本条各号に定めるほか、当社は、お客様が受診を希望される医療施設又は薬局においてオンライン診療等又はオンライン服薬指導が実施・継続されることを保証するものではなく、これらの実施については各医療施設・薬局と協議の上、本サービスをご利用ください。",
    },
  },
  article18: {
    heading: "第１４条 （損害賠償額の制限）",
    text: {
      j001: "1. 前条に基づく本サービスのサービス内容の変更又は停止若しくは終了等に起因する場合を含め、当社は、お客様の本サービスへの登録及び本サービスの利用から生じる一切の損害に関して、当社に故意又は重過失がない限り、責任を負わないものとします。",
      j002: "2. 当社が、お客様に対して責任を負う場合であっても、当社の責任は、債務不履行、不法行為その他の請求の原因のいかんを問わず、お客様に現実に発生した直接かつ通常の損害の賠償に限られ、かつ、３万円を上限として、当該損害を賠償するものとします。",
      j003: "3. お客様が、本サービスに関連して第三者との間で生じた紛争等については、お客様と第三者との間でお客様自身の費用と責任において解決するものとし、当社は、これらの紛争等については、当該紛争が本サービスの不具合等（当該不具合が当社の故意又は重過失に起因する場合に限ります。）に起因する場合を除き、一切責任を負うことなく、また、その解決に関与する義務を負うものではありません。",
    },
  },
  article19: {
    heading: "第１５条 （禁止行為・損害賠償）",
    text: {
      j001: "1. お客様は以下のいずれかに該当する行為（以下、「禁止行為」といいます。）を行ってはなりません。",
      j002: "（1） 法令等、本規約等（第１１条の誓約事項を含みます。）に違反し、又は、そのおそれのある行為",
      j003: "（2） 本サービスの利用に関し、自ら又は第三者のために不正の利益を得ようとする行為",
      j004: "（3） 当社又は当社にライセンスを許諾している第三者の知的財産権、他人の名誉、信用、プライバシーその他の権利を侵害する行為、又はそれを助長する行為",
      j005: "（4） 当社又は第三者に不利益若しくは損害を与える行為、又はそのおそれのある行為",
      j006: "（5） 当社のシステムへの不正アクセス、コンピュータウィルスの配布、その他本サービスの正常な運営を妨げる行為",
      j007: "（6） 法令等若しくは公序良俗に違反する行為、又はそれらに結びつく行為若しくはそれらを助長する行為",
      j008: "（7） 本サービスの利用に関し、当社、登録医療施設若しくは登録医師又は登録薬局若しくは登録薬剤師に対して、虚偽の情報を提供する行為",
      j009: "（8） その他前各号に準ずる行為",
      j010: "2. お客様が禁止行為を行った場合、又はそのおそれがあると当社が判断した場合は、当社は公的機関への通報を行う場合があります。",
      j011: "3. お客様が禁止行為を行ったことにより、当社又は第三者に対して損害を与えた場合は、お客様は当社又は第三者に対し損害賠償義務を負うものとします。",
    },
  },
  article20: {
    heading: "第１６条 （解除）",
    text: {
      j001: "1. 当社は、お客様に以下の事由が存在するものと判断した場合、お客様に通告することなく、お客様に対する本サービスの提供の一部又は全部の停止、本契約の解除その他当社が必要と認める措置を講じることができるものとします。なお、当社はお客様に対し、当社の措置により生じたお客様の損害等について一切責任を負わないものとし、また、かかる措置を講じる理由について説明する義務を負わないものとします。ただし、解除の原因となった事由が本契約及び取引上の社会通念に照らして軽微であるときは、相当期間を定めた上でその履行の催告をし、その期間内に履行がないときに、契約の解除をすることができるものとします。なお、民法第５４３条の規定は、その適用を排除するものとします。",
      j002: "（1） お客様による第２条第２項に定める表明が虚偽又は不正確であることが判明した場合",
      j003: "（2） お客様が前条第１項に違反した場合、又はそのおそれがある場合",
      j004: "（3） お客様による本サービスの利用が不適切である場合",
      j005: "（4） お客様による本サービスの利用により本サービスの運営に支障が生じる場合、又はそのおそれがある場合",
      j006: "（5） お客様の重要な財産に対する差押、仮差押、仮処分、租税延滞処分その他公権力の処分を受けた場合",
      j007: "（6） お客様につき破産手続開始又は民事再生手続開始の申立が行われた場合",
      j008: "（7） その他お客様の財産状況が悪化した場合、又はそのおそれがある場合",
      j009: "（8） 次条の表明・確約に違反が存在することが判明した場合",
      j010: "（9） 法令等の改正、成立等により、登録医師、登録医療施設、登録薬局若しくは登録薬剤師によるオンライン診療等若しくはオンライン服薬指導に係る業務の提供が不能となった場合その他本契約を継続しがたい事由がある場合",
      j011: "2. お客様が本契約を解約される場合は、別途当社が定める方法により行うものとします。",
    },
  },
  article21: {
    heading: "第１７条 （反社会的勢力の排除）",
    text: {
      j001: "1. お客様は、現在、暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力団等、その他これらに準ずる者に該当しないことを表明し、かつ将来にわたっても該当しないことを確約します。",
      j002: "2. お客様は、自ら又は第三者を利用して次の各号の一にでも該当する行為を行わないことを確約します。",
      j003: "（1） 暴力的な要求行為",
      j004: "（2） 法的な責任を超えた不当な要求行為",
      j005: "（3） 取引に関して、脅迫的な言動をし、又は暴力を用いる行為",
      j006: "（4） 風説を流布し、偽計を用い又は威力を用いて、当社若しくは第三者の信用を毀損し、又は、当社若しくは第三者の業務を妨害する行為",
      j007: "（5） その他前各号に準ずる行為",
    },
  },
  article22: {
    heading: "第１８条 （業務委託）",
    text: {
      j001: "当社は、本サービスに係る業務の一部又は全部を第三者に委託することができるものとします。",
    },
  },
  article23: {
    heading: "第１９条 （権利義務の譲渡）",
    text: {
      j001: "1. お客様は、お客様に係るいかなる権利又は義務も第三者に移転又は譲渡、貸与、担保提供等をすることはできません。",
      j002: "2. 当社は、お客様との間の本契約上の権利義務の全部又は一部を、お客様の同意なく、第三者に譲渡し、又は承継させることができるものとします｡当社はこれらの行為によって生じるいかなる損害及び障害等に関しても、一切の責任を負わないものとします｡",
    },
  },
  article24: {
    heading: "第２０条 （本規約の変更）",
    text: {
      j001: "当社は、変更された本規約の施行時期を定めた上で、本サービス上で掲示する等の方法でお客様に通知する方法で、本規約を変更することができるものとします。本規約の変更内容が本サービスで公開された後、最初にお客様が本サービスを利用した時点でお客様は当該変更内容に同意したものとみなします。",
    },
  },
  article25: {
    heading: "第２１条 （準拠法・管轄）",
    text: {
      j001: "1. 本契約の成立、効力、履行及び解釈に関しては、日本法が適用されるものとします。",
      j002: "2. 本サービスに関連して、お客様と当社との間で紛争（裁判所の調停手続きを含む。）が生じた場合は、訴額のいかんにかかわらず、東京地方裁判所を専属的合意管轄裁判所とするものとします。",
    },
  },
  cancelPolicy: {
    heading: "キャンセルポリシー ",
    text: {
      j001: "1．利用規約第３条第１０項に定める診療等の予約のキャンセルとは、お客様によるキャンセル操作が行われた場合（診療等の開始時刻が経過した後はキャンセル操作を行うことができません。）又は予約された診療終了時刻が到来するまでに診療等が開始されなかった場合をいいます。このうち、診療等のキャンセル操作が実施されておらず、かつ予約された診療等の終了時間までに診療等が開始されていない場合で、診療等が開始されないことについて医療施設に連絡がされていない場合は、「無断キャンセル」になります。 ",
      j002: "2．診療等の予約がキャンセルされた場合又は無断キャンセルされた場合、予約された診療等の種類により、以下の定めるとおりキャンセル料が発生いたします。 ",
      j003: "＜オンライン診療（保険適用）の場合＞",
      j004: "キャンセル料は発生いたしません。",
      j005: "＜オンライン診療（保険適用外）の場合＞",
      j006: "診療日前日まで：無料",
      j007: "診療日当日：医療施設のメニュー説明文に別途定めがある場合、診療費用等の見込み金額の全部又は一部をご請求する場合があります。",
      j008: "＜オンライン相談の場合＞",
      j009: "相談日前日まで：無料",
      j010: "相談日当日：医療機関（医療施設と薬局をいいます。）のメニュー説明文に別途定めがある場合、相談費用等の見込み金額の全部又は一部をご請求する場合があります。",
      j011: "＜オンライン服薬指導の場合＞",
      j012: "キャンセル料は発生いたしません。",
      j013: "処方箋には有効期限がございますので、必ず薬局とスケジュール調整等を行ってください。",
      j014: "3．診療等の予約が無断キャンセルされた場合、利用規約第１１条５号の以下の条項が適用されます。 ",
      j015: "(5) 無断キャンセルや短期間における複数回の予約キャンセル等、登録医療施設又は登録薬局の業務の妨げになる態様での予約のキャンセルを行わないこと ",
    },
  },
  date: {
    j001: "２０１８年　４月２６日 制定",
    j002: "２０１８年　９月１８日 改定",
    j003: "２０１８年１０月　５日 改定",
    j004: "２０１９年　２月１２日 改定",
    j005: "２０１９年　９月１２日 改定",
    j006: "２０２０年　５月　１日 改定",
    j007: "２０２１年　２月　１日 改定",
    j008: "２０２１年　５月３１日 改定",
    j009: "２０２１年１０月　１日 改定",
    j010: "２０２２年１０月１０日 改定",
    j011: "２０２３年　９月１５日 改定",
    j012: "２０２４年　９月１３日 改定",
    j013: "２０２５年　３月２８日 改定",
  },
};
export default JapaneseTermsOfService;
