import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { withStyles } from "@material-ui/core/styles";
import { Hub } from "aws-amplify";
import { Component } from "react";
import { japaneseList } from "../../../Resources/japaneseList";

const styles = (theme) => ({
  snackbar: {
    position: "fixed",
  },
  snackbarContent: {
    width: 360,
    backgroundColor: "white",
    color: "red",
  },
});

const MESSAGE_EVENT = {
  OPEN_MANUAL_TOAST: "open", // ユーザーがボタンを押しても閉じるトースト
  OPEN_AUTO_TOAST: "openAutoToast", // ボタンがなく、自動で閉じるトースト
  CLOSE: "close",
};

class MessageBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      showCloseButton: false,
      message: null,
    };

    Hub.listen("msg", this.hubAuthListener);
  }

  hubAuthListener = (data) => {
    switch (data.payload.event) {
      case MESSAGE_EVENT.OPEN_MANUAL_TOAST:
        this.setState({
          open: true,
          showCloseButton: true,
          message: data.payload.data.message,
          autoHideDuration: data.payload.data.autoHideDuration,
        });
        break;
      case MESSAGE_EVENT.OPEN_AUTO_TOAST:
        this.setState({
          open: true,
          showCloseButton: false,
          message: data.payload.data.message,
        });
        break;
      case MESSAGE_EVENT.CLOSE:
        this.setState({
          open: false,
          message: null,
        });
        break;
      default:
        break;
    }
  };

  close = () => {
    this.setState({ open: false });
  };
  handleClick = () => {
    this.close();
  };

  render() {
    const { classes } = this.props;
    return (
      <Snackbar
        open={this.state.open}
        autoHideDuration={
          this.state.autoHideDuration ? this.state.autoHideDuration : 3000
        }
        onClose={this.close}
        snackbarcontentprops={{
          "aria-describedby": "snackbar-fab-message-id",
          className: classes.snackbarContent,
        }}
        message={this.state.message}
        action={
          this.state.showCloseButton && (
            <Button color="inherit" size="small" onClick={this.handleClick}>
              {japaneseList.Components.MessageBox.render.Snackbar.Button.j001}
            </Button>
          )
        }
        className={classes.snackbar}
      />
    );
  }
}

export default withStyles(styles)(MessageBox);
