import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Hub } from "aws-amplify";

import Heading1 from "../../atoms/headings/Heading1";
import PaymentCardUpdate from "../../organisms/payment/PaymentCardUpdate";
import BasicTemplate from "../../templates/BasicTemplate";

import Guard from "../../../Utils/Guard";
import { japaneseList } from "../../../Resources/japaneseList";
import Titles from "../../../Resources/Titles";
import { sessionBrowserStorage } from "../../../Utils/BrowserStorage";

const Container = styled.div`
  padding: ${(p) => p.theme.space.normal};
`;

class RegisterPage extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    // 外部サイトに一度リダイレクトするためストレージに保存
    const { reserveData, reserveInput } = this.props.location.state;
    reserveData && sessionBrowserStorage?.set("reserveData", reserveData);
    reserveInput && sessionBrowserStorage?.set("reserveInput", reserveInput);
  }

  onRegisterCardError = (message) => {
    Hub.dispatch(
      "msg",
      {
        event: "open",
        data: { message, level: "error", autoHideDuration: 8000 },
      },
      "PaymentCard"
    );
  };

  render() {
    const renderResource = japaneseList.pages.payment.RegisterPage.render;
    const main = (
      <React.Fragment>
        <Heading1>{renderResource.Fragment.PrimaryHeading.j001}</Heading1>
        <Container>
          <PaymentCardUpdate showError={this.onRegisterCardError} />
        </Container>
      </React.Fragment>
    );
    return <BasicTemplate title={Titles.cardRegister} main={main} />;
  }
}

export default withRouter(Guard(RegisterPage));
