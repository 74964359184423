import { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { isLnln } from "../../../Utils/checkLnln";
import RegisterPath from "../../../images/carada/registered.svg";
import RegisterPathLnln from "../../../images/lnln/registered.svg";
import SiteContext from "../../../SiteContext";
import { device } from "../../device";

const CompleteModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(102, 102, 102, 0.7);
  z-index: 90;
  touch-action: none;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s ease;
  .fade-in {
    animation: fadeIn 1s ease;
  }
  .fade-out {
    animation: fadeOut 1s ease;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @media screen and ${device.tb} {
    left: 50%;
    width: 424px;
    margin-left: -212px;
  }
  @media screen and ${device.pc} {
    width: 424px;
  }
`;

const Image = styled.img`
  width: 332px;
  height: 233px;
  border-radius: 5px 0px 0px 0px;
  padding: 30px 20px 30px 20px;
`;

// TODO: 将来的には comp/organisms/global/SuccessDialogに統合する
class RegisterCompleteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCompleteModal: true,
      fadeOutStart: false,
      isLoading: true,
    };
  }
  static contextType = SiteContext;
  componentDidMount() {
    setTimeout(() => this.setState({ showCompleteModal: false }), 4000);
    setTimeout(() => this.setState({ fadeOutStart: true }), 3000);
    const intervalId = setInterval(() => {
      const contextValue = this.context;
      this.state.isLoading = contextValue.isLoading;
      if (this.state.isLoading !== true) {
        this.setState({
          isLoading: this.state.isLoading,
        });
      }
    }, 500);
    setTimeout(() => clearInterval(intervalId), 4000);
  }

  render() {
    const imgPath = isLnln() ? RegisterPathLnln : RegisterPath;
    if (this.state.showCompleteModal) {
      return (
        <CompleteModal
          style={{
            animation: this.state.fadeOutStart ? "fadeOut 1s ease" : {},
            backgroundColor: this.state.isLoading
              ? "rgba(0, 0, 0, 0)"
              : "rgba(102, 102, 102, 0.7)",
          }}
        >
          <Image
            src={imgPath}
            className={this.state.fadeOutStart ? "fade-out" : "fade-in"}
          />
        </CompleteModal>
      );
    } else {
      return null;
    }
  }
}

export default withRouter(RegisterCompleteModal);
