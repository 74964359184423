import styled from "styled-components";
import Button from "./Button";

const ButtonNoBorder = styled(Button)`
  border: none;
  background: none;
  padding: 0;
`;

export default ButtonNoBorder;
