import React, { ReactNode, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";
import ButtonOutlined from "../../atoms/buttons/ButtonOutlined";
import styled from "styled-components";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

const styles = () => ({
  dialogPaper: {
    maxHeight: "90%",
    width: "90vw",
    maxWidth: "690px",
    margin: 0,
  },
  dialogContent: {
    width: "100%",
    padding: "0px 20px 0px 20px !important", // 勝手にpadding-topが付くため !important
    margin: "20px 0 14px 0",
    "white-space": "pre-wrap",
    display: "flex",
    "flex-direction": "column",
    alignItems: "center",
    gap: "14px",
  },
  dialogTitle: {
    "text-align": "center",
    "white-space": "pre-wrap",
    maxWidth: "90%",
    padding: 0,
    margin: 0,
  },
  dialogAction: {
    width: "100%",
    display: "grid",
    "grid-template-columns": "1fr",
    "grid-gap": "14px",
    padding: "0 20px 30px 20px",
    margin: 0,
  },
  dialogButton: {
    margin: 0,
    height: "40px",
    "font-size": "14px",
    "font-weight": "bold",
  },
});
const DialogTitleText = styled.span`
  font-size: 16px;
  color: ${(p) => p.theme.color.primary};
  font-weight: 600;
  line-height: 22px;
`;

const DialogContentText = styled.p`
  font-size: 12px;
  font-weight: 300;
  color: #393939;
  line-height: 22px;
  margin: 0;
  width: 100%;
`;

interface GAid {
  closeBtnId?: string;
}

interface Props {
  classes: any;
  isOpen: boolean;
  handleClosePopup: () => void;
  confirmResource: any;
  buttonGAid?: GAid;
  dialogContentText?: ReactNode | string;
}

const popup: React.FC<Props> = (props) => {
  const {
    classes,
    isOpen,
    handleClosePopup,
    confirmResource,
    buttonGAid,
    dialogContentText,
  } = props;

  const handleClose = useCallback((e) => {
    e.preventDefault();
    handleClosePopup();
  }, []);

  return (
    <React.Fragment>
      <Dialog open={isOpen} classes={{ paper: classes.dialogPaper }}>
        <DialogContent className={classes.dialogContent}>
          <DialogTitle className={classes.dialogTitle}>
            <DialogTitleText>{confirmResource.j001}</DialogTitleText>
          </DialogTitle>
          <DialogContentText>
            {dialogContentText || (
              <DialogContentText>{confirmResource.j002}</DialogContentText>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <ButtonOutlined
            className={classes.dialogButton}
            onClick={handleClose}
            id={buttonGAid?.closeBtnId}
          >
            {confirmResource.j003}
          </ButtonOutlined>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export const LongInfoPopup = withStyles(styles)(popup);
