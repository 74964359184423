import styled from "styled-components";
import QuestionIcon from "../../../images/icon/icon-question.svg";
interface Props {
  size?: string;
}

const Img = styled.img<Props>`
  height: ${(p) => p.size || "1rem"};
`;

export const IconQuestion = (props: Props) => (
  <Img src={QuestionIcon} {...props} />
);
