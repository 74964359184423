import React, { useState } from "react";
import styled from "styled-components";
import DOMPurify from "dompurify";
import parse from "html-react-parser";

import { LongInfoPopup } from "../molecules/popup/LongInfoPopup";
import { IconArrow } from "../atoms/icons/IconArrow";
import FontP from "../atoms/fonts/FontP";
import ButtonNoBorder from "../atoms/buttons/ButtonNoBorder";

interface NotificationData {
  title: string;
  modal_title: string;
  modal_description: string | React.ReactNode;
}

import LinkTo from "../atoms/links/LinkTo";
const ToUNews = () => {
  return (
    <>
      本人認証サービス(3Dセキュア2.0) の導入に伴い、利用規約を改定いたします。
      <br />
      【改定日】
      <br />
      2025年3月28日
      <br />
      <br />
      【改定箇所】
      <br />
      ・第6条の5（決済方法）
      <br />
      <br />
      詳細は左上のメニューから
      <LinkTo to="/terms-of-service">
        利用規約
      </LinkTo>
      をご確認ください。
    </>
  );
};
/**
 * TOPに表示するお知らせを格納する
 * title: TOPに表示される見出し
 * modal_title: お知らせを押した後に表示されるモーダルの見出し
 * modal_description:  お知らせを押した後に表示されるモーダルの本文
 *   簡単なHTMLなら文字列にして書けます
 *   複雑な場合、本ファイル内に新しい使い捨てコンポーネントを作ってください
 */
const NOTIFICATION_LIST: NotificationData[] = [
  {
    title:
      "【重要】クレジットカード情報登録時の本人認証サービス「3Dセキュア2.0」導入のお知らせ",
    modal_title:
      "クレジットカード情報登録時の本人認証サービス「3Dセキュア2.0」導入のお知らせ",
    modal_description: `CARADA/ルナルナ オンライン診療をご利用いただき、誠にありがとうございます。
より安心・安全にサービスをご利用いただくため、クレジットカード情報登録時に本人認証サービス「3Dセキュア2.0」を導入いたします。
導入以降、クレジットカードをご登録いただく際には、「3Dセキュア2.0」に対応したクレジットカードをご登録いただく必要があります。ご不便をおかけしますが、ご理解いただきますようお願い申し上げます。

■クレジットカード本人認証サービス「3Dセキュア2.0」とは
クレジットカードの不正利用を防止するための本人認証サービスです。
クレジットカード登録時に、不正利用のリスクが高いと判断された場合のみ、本人確認を求めます。パスワード入力やデバイスでの追加認証を行うことで、より安全にクレジットカードをご利用いただくことができます。

■導入予定日
2025年3月28日(金)

■対象のお客様
導入開始以降にクレジットカード情報を登録・更新いただくお​客様

■対象となるカード
VISA/MasterCard/JCB/American Express/Diners Club

■注意事項
・「3Dセキュア2.0」を利用するには、ご利用のカード発行会社で事前登録が必要となる場合があります。登録方法やカード情報については、各カード発行会社へ直接お問合せください。`,
  },
  {
    title: "利用規約改定のお知らせ",
    modal_title: "＜利用規約改定のお知らせ＞",
    modal_description: <ToUNews />,
  },
];

const NotificationsContainer = styled.div`
  margin: 0 15px 20px 15px;
`;

const HomeNotifications: React.FC = () => {
  return (
    <NotificationsContainer>
      {NOTIFICATION_LIST.map((DATA) => (
        <Notification {...DATA} />
      ))}
    </NotificationsContainer>
  );
};

const NotificationContainer = styled(ButtonNoBorder)`
  background: #fff0f0;
  display: grid;
  grid-template-columns: 90% 10%;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding: 10px 15px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
  :active {
    background-color: #ffcccc;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;
const StyledIconArrow = styled(IconArrow)`
  &::after {
    border-color: #cccccc;
    border-width: 2px;
  }
`;

const Notification: React.FC<NotificationData> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const confirmResource = {
    j001: props.modal_title,
    j002:
      typeof props.modal_description === "string"
        ? parse(DOMPurify.sanitize(props.modal_description))
        : props.modal_description,
    j003: "閉じる",
  };
  return (
    <>
      <NotificationContainer onClick={handleOpen}>
        <FontP align="left" weight="default" size="ss" color="black">
          {props.title}
        </FontP>
        <IconWrapper>
          <StyledIconArrow size="8px" />
        </IconWrapper>
      </NotificationContainer>
      <LongInfoPopup
        isOpen={isOpen}
        handleClosePopup={handleClose}
        confirmResource={confirmResource}
        // buttonGAid={}
      />
    </>
  );
};

export default HomeNotifications;
