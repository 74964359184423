// import { useMemo } from "react";
import { createBrowserStorage } from "../Utils/BrowserStorage";

export function useBrowserStorage(storage: Storage) {
  const browserStorage = createBrowserStorage(storage);

  // Storageが使えない場合でも他の処理に影響ないようにする
  if (!browserStorage) {
    return {
      set: () => {},
      get: () => null,
      remove: () => {},
      clear: () => {},
    };
  }

  return {
    set: browserStorage.set.bind(browserStorage),
    get: browserStorage.get.bind(browserStorage),
    remove: browserStorage.remove.bind(browserStorage),
    clear: browserStorage.clear.bind(browserStorage),
  };
}

export function useLocalStorage() {
  return useBrowserStorage(window.localStorage);
}

export function useSessionStorage() {
  return useBrowserStorage(window.sessionStorage);
}
