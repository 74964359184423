const Titles = {
  home: "",

  signUp: "会員登録",
  signUpConfirmation: "認証コード",
  singIn: "ログイン",
  forgotPassword: "パスワード再設定",
  newPasswordRequired: "パスワード再設定",
  notificationList: "お知らせ",
  hospitalDetail: "病院詳細",
  reserve: "予約",
  reserveConfirm: "予約内容確認",
  reserveComplete: "予約完了",
  appointment: "予約詳細",
  searchPrefecture: "都道府県から探す",
  searchResult: "検索結果",
  // video
  onlineChat: "ビデオ通話",
  onlineChatFinish: "ビデオ通話終了",
  // payment
  cardInfo: "クレジットカード情報",
  cardRegister: "クレジットカード情報登録",
  cardUpdate: "クレジットカード情報変更",
  cardRegistering: "クレジットカード登録処理中",
  // mypage
  mypage: "マイページ",
  profileupdate: "会員情報",
  changepassword: "パスワード",
  addressDetail: "住所情報",
  address: "住所更新",
  email: "メールアドレス",
  emailComplate: "メールアドレス完了",
  verifyEmail: "メールアドレス",
  insuranceCard: "保険証情報",
  insuranceCardConfirm: "保険証情報",
  insuranceCardComplete: "保険証情報",
  insuranceCardErr: "保険証情報",
  // enterprise
  enterpriseTop: "ルナルナ オフィス利用登録",
  enterpriseAuthentication: "ルナルナ オフィス利用登録",
  EnterpriseAuthorization: "招待コード入力",
  enterpriseConfirm: "登録内容確認",
  enterpriseCompleted: "ルナルナ オフィス利用登録完了",
  searchEnterprise: "ルナルナ オフィス：受診可能な医療機関",
  // other
  termsOfService: "利用規約",
  consumerPolicy: "特定商取引法",
  privacyPolicy: "個人情報の取り扱いについて",
  applicationPrivacyPolicy: "サイト・プライバシーポリシー",
  inquiry: "ヘルプ・お問い合わせ",
  inquirySignupSmsCompleted: "お問い合わせ完了",

  notFound: "404 Not found",
  notSupport: "",
  notAccess: "",
};

export default Titles;
