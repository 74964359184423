import { FC, useState, useContext, useEffect } from "react";
import { Hub } from "aws-amplify";
import { HubCallback } from "@aws-amplify/core/lib-esm/Hub";

import styled from "styled-components";

import { isLnln } from "../../../Utils/checkLnln";
import IconSuccessPath from "../../../images/carada/icon/icon-success.svg";
import IconSuccessPathLnln from "../../../images/lnln/icon/icon-success.svg";
import { device } from "../../device";
import SiteContext from "../../../SiteContext";

const IconPath = isLnln() ? IconSuccessPathLnln : IconSuccessPath;

const CompleteModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(102, 102, 102, 0.7);
  z-index: 90;
  touch-action: none;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s ease;
  .fade-in {
    animation: fadeIn 1s ease;
  }
  .fade-out {
    animation: fadeOut 1s ease;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @media screen and ${device.tb} {
    left: 50%;
    width: 424px;
    margin-left: -212px;
  }
  @media screen and ${device.pc} {
    width: 424px;
  }
`;

const ModalMain = styled.div`
  background-color: #fff;
  border-radius: 5px;
  width: 80%;
  max-height: 233px;
  padding: 30px 20px 30px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

const Image = styled.img`
  width: 50px;
  height: 50px;
`;

// Themeが使えないため再定義
const FontBase = styled.p`
  padding: 0;
  margin: 0;
  text-align: center;
  color: #333;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.5;
  white-space: pre-wrap;
`;

const CHANNEL_NAME = "success";
// TODO: 何秒で閉じるかをいじれるようにする
export const showSuccessDialog = (message: string): void => {
  Hub.dispatch(CHANNEL_NAME, {
    event: "open",
    data: {
      message,
      level: "info",
    },
  });
};

// App.jsでグローバルに使う
export const SuccessDialog: FC = () => {
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [fadeOutStart, setFadeOutStart] = useState(false);
  const [message, setMessage] = useState("");
  const { isLoading } = useContext(SiteContext);

  useEffect(() => {
    const hubAuthListener: HubCallback = (data) => {
      if (data.payload.event === "open") {
        setMessage(data.payload.data.message);
        setShowCompleteModal(true);
        setFadeOutStart(false);
        setTimeout(() => setShowCompleteModal(false), 4000);
        setTimeout(() => setFadeOutStart(true), 3000);
      }
    };

    Hub.listen(CHANNEL_NAME, hubAuthListener);

    return () => {
      Hub.remove(CHANNEL_NAME, hubAuthListener);
    };
  }, []);

  return showCompleteModal ? (
    <CompleteModal
      style={{
        animation: fadeOutStart ? "fadeOut 1s ease" : "fadeIn 1s ease",
        backgroundColor: isLoading
          ? "rgba(0, 0, 0, 0)"
          : "rgba(102, 102, 102, 0.7)",
      }}
    >
      <ModalMain>
        <Image
          src={IconPath}
          className={fadeOutStart ? "fade-out" : "fade-in"}
        />
        <FontBase>{message}</FontBase>
      </ModalMain>
    </CompleteModal>
  ) : null;
};
