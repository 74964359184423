import styled, { keyframes } from "styled-components";

interface Props {
  color?: string;
}

const scale = keyframes`
  0%,
  100%{
    opacity:1
  }
  80%{
    opacity:.8
  }
  60%{
    opacity:.6
  }
  40%{
    opacity:.4
  }
  20%{
    opacity:.2
  }
`;

const Container = styled.div<Props>`
  text-align: center;
  div {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin: 30px 5px 30px 5px;
    border-radius: 100%;
    background-color: ${(p) => p.theme.color[p.color || "primary"]};
  }
  div:nth-child(1) {
    animation: ${scale} 1.25s -0.48s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }
  div:nth-child(2) {
    animation: ${scale} 1.25s -0.36s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }
  div:nth-child(3) {
    animation: ${scale} 1.25s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }
  div:nth-child(4) {
    animation: ${scale} 1.25s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }
  div:nth-child(5) {
    animation: ${scale} 1.25s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }
`;

const StandbyLoadingBall: React.FC<Props> = ({ color }) => (
  <Container color={color}>
    <div />
    <div />
    <div />
    <div />
    <div />
  </Container>
);

export default StandbyLoadingBall;
