import React from "react";
import styled from "styled-components";

import { IconInfo2 } from "../../atoms/icons/IconInfo2";
import { Accordion } from "../../atoms/others/Accordion";
import FontP from "../../atoms/fonts/FontP";
import { japaneseList } from "../../../Resources/japaneseList";

const renderResource = japaneseList.organisms.payment.AuthCreditCardInfo;

const StyledIconInfo = styled(IconInfo2)`
  margin-right: ${({ theme }) => theme.space.short};
  height: 1.5rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.font.size.ss};
`;

const Title: React.FC = () => {
  return (
    <TitleWrapper>
      <StyledIconInfo />
      <span>{renderResource.j001}</span>
    </TitleWrapper>
  );
};

export const AuthCreditCardInfo: React.FC = () => {
  return (
    <Accordion title={<Title />}>
      <FontP size="ss">{renderResource.j002}</FontP>
    </Accordion>
  );
};
