import styled from "styled-components";
import { ReactComponent as InfoIcon } from "../../../images/icon/icon-info2.svg";
interface Props {
  size?: string;
}

const StyledSvg = styled(InfoIcon)<Props>`
  height: ${(p) => p.size || "1rem"};
  fill: ${({ theme }) => theme.color.primary};
`;

export const IconInfo2 = (props: Props) => <StyledSvg {...props} />;
