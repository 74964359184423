import React, { Component } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { format } from "date-fns";
import * as Sentry from "@sentry/browser";

import { isLnln } from "../../../Utils/checkLnln";
import client from "../../../Utils/GraphQLClient";
import PaymentDialog from "./PaymentDialog";
import ErrorList from "./PaymentErrorList";
import RecaptchaErrorList from "../recaptcha/RecaptchaErrorList";

import { IconQuestion } from "../../atoms/icons/IconQuestion";
import InputTel from "../../atoms/forms/InputTel";
import InputText from "../../atoms/forms/InputText";
import FontP from "../../atoms/fonts/FontP";
import ButtonFullWidth from "../../atoms/buttons/ButtonFullWidth";
import SelectMonth from "../../molecules/forms/SelectMonth";
import SelectYear from "../../molecules/forms/SelectYear";
import InvisibleRecaptcha from "../recaptcha/InvisibleRecaptcha";
import { AuthCreditCardInfo } from "../../molecules/accordion/AuthCreditCardInfo";

import { japaneseList } from "../../../Resources/japaneseList";

import ImgCreditCardPath from "../../../images/CreditCard.png";
import { Account, AuthCreditCard } from "../../../Utils/GraphQLQueries";
import { sessionBrowserStorage } from "../../../Utils/BrowserStorage";

const PaymentCardUpdateResource =
  japaneseList.organisms.payment.PaymentCardUpdate;
const PaymentErrorListResource =
  japaneseList.organisms.payment.PaymentErrorList;

const ImageBlock = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Image = styled.img`
  height: 38px;
`;

const CustomFontP = styled(FontP)`
  margin: 0 0 0.5em 0;
`;
const CustomFontPBold = styled(FontP)`
  margin: 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const InputTelFull = styled(InputTel)`
  width: 100%;
`;
const InputTextFull = styled(InputText)`
  width: 100%;
`;

const Spacer = styled.span`
  display: inline-block;
  padding: 4px;
`;

const ThreeDSecureDescription = styled(FontP)`
  background-color: #f7f7f7;
  padding: 15px;
`;

class PaymentCardUpdate extends Component {
  static defaultProps = {
    handleClickRegisterButton: function () {},
    showError: function () {},
    cardSeq: null,
  };

  state = {
    cardNo: "",
    expireMonth: "01",
    expireYear: format(new Date(), "YYYY"),
    securityCode: "",
    holderName: "",
    openDialog: false,
    isCardRegistered: false,
  };

  constructor(props) {
    super(props);
    // 3Dセキュアで外部サイトに遷移する前にカード連番を保存する
    const { cardSeq } = this.props;
    cardSeq && sessionBrowserStorage?.set("cardSeq", cardSeq);
    // Append Script tag for Payment Service's library
    if (!document.getElementById("payment-lib-script")) {
      const script = document.createElement("script");
      script.id = "payment-lib-script";
      script.src = process.env.REACT_APP_PAYMENT_LIB_PATH;
      document.head.appendChild(script);
    }
  }

  async componentDidMount() {
    await this.isCardRegistered();
  }

  handleChange = (event) => {
    const target = event.currentTarget;
    this.setState({
      [target.name]: target.value,
    });
  };

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  handleOpen = () => {
    this.setState({ openDialog: true });
  };

  showTokenError(errorCode) {
    const message = ErrorList[errorCode] || errorCode;
    this.props.showError(message);
  }

  authCreditCard = async (gmoTokens, recaptchaToken) => {
    const siteType = isLnln() ? "lnln" : "carada";

    const { data, errors } = await client
      .mutate({
        mutation: AuthCreditCard,
        variables: {
          gmoToken: gmoTokens[0],
          recaptchaToken,
          siteType,
        },
      })
      .catch((error) => {
        Sentry.captureException(error);
        return { error };
      });
    if (errors) {
      const RecaptchaErrorTypes = Object.keys(RecaptchaErrorList);
      const errorMessages = errors.map((error) => {
        const errorType = error.errorType;
        if (RecaptchaErrorTypes.includes(errorType)) {
          return RecaptchaErrorList[errorType];
        } else {
          return PaymentCardUpdateResource.onGetToken.j001;
        }
      });
      this.props.showError(errorMessages[0]);
      return;
    }
    Sentry.captureMessage("credit-card-auth", Sentry.Severity.Log);

    try {
      const redirectUrl = new URL(data?.authCreditCard);
      window.location.href = redirectUrl.href;
    } catch (e) {
      Sentry.captureException(e);
      this.props.showError(PaymentCardUpdateResource.onGetToken.j001);
    }
  };

  isCardRegistered = async () => {
    const {
      data: { account },
    } = await client.query({ query: Account });
    if (account && account.cardStatus === "registered") {
      this.setState({ isCardRegistered: true });
    }
  };

  /**
   * クライアント側で行うバリデーション
   * 基本的にはGMOのトークン取得時に制御が入るため不要
   */
  validate = () => {
    /**
     * 3Dセキュア導入時に名義人も必須項目になったが、GMO側の必須チェックがないため登録処理が進んでしまう
     * 仕様としてカード名義を必須にしたため、入力していない場合をバリデーションする
     * ※ 国際ブランドでカード名義を必須としているのはVISAのみだがまとめて必須とする
     * */
    if (!this.state.holderName) {
      this.showTokenError(PaymentErrorListResource.j099);
      return false;
    }
    return true;
  };
  recaptchaTokenCallback = (recaptchaToken) => {
    if (!this.validate()) return;
    this.props.handleClickRegisterButton();
    if (!this.props.cardSeq && this.state.isCardRegistered) {
      this.props.showError(
        japaneseList.pages.payment.RegisterPage.validateLimitOneCard.j001
      );
      return;
    }
    window.tokenCallback = (response) => {
      if (response.resultCode !== "000") {
        this.showTokenError(response.resultCode);
        return;
      }

      const gmoToken = response.tokenObject.token;
      this.authCreditCard(gmoToken, recaptchaToken);
    };

    const { cardNo, expireYear, expireMonth, securityCode, holderName } =
      this.state;
    const cardInfo = {
      cardno: cardNo,
      expire: expireYear + expireMonth,
      securitycode: securityCode,
      holdername: holderName,
      tokennumber: 1,
    };

    window.Multipayment.init(process.env.REACT_APP_PAYMENT_SHOP_ID);
    window.Multipayment.getToken(cardInfo, window.tokenCallback);
  };

  render() {
    const { cardNo, expireYear, expireMonth, securityCode, holderName } =
      this.state;
    return (
      <React.Fragment>
        <CustomFontP>{PaymentCardUpdateResource.render.Desc.j001}</CustomFontP>
        <ImageBlock>
          <Image src={ImgCreditCardPath} style={{ marginLeft: "5px" }} />
        </ImageBlock>
        <AuthCreditCardInfo />
        <form autoComplete="on">
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <CustomFontPBold>
                {PaymentCardUpdateResource.render.TitleText.j001}
              </CustomFontPBold>
              <InputTelFull
                name="cardNo"
                placeholder={PaymentCardUpdateResource.render.Placeholder.j001}
                maxLength={16}
                value={cardNo}
                onChange={this.handleChange}
                required
                autoComplete="cc-number"
              />
            </Grid>
            <Grid item xs={12}>
              <CustomFontPBold>
                {PaymentCardUpdateResource.render.TitleText.j004}
              </CustomFontPBold>
              <InputTextFull
                name="holderName"
                placeholder={PaymentCardUpdateResource.render.Placeholder.j004}
                inputMode="email" // モバイル端末でキーボードを英字にする
                maxLength={50}
                value={holderName}
                onChange={this.handleChange}
                required
                autoComplete="cc-name"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomFontPBold>
                {PaymentCardUpdateResource.render.TitleText.j002}
              </CustomFontPBold>
              <SelectMonth
                name="expireMonth"
                value={expireMonth}
                onChange={this.handleChange}
                autoComplete="cc-exp-month"
              />
              <Spacer>/</Spacer>
              <SelectYear
                name="expireYear"
                value={expireYear}
                onChange={this.handleChange}
                autoComplete="cc-exp-year"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomFontPBold>
                {PaymentCardUpdateResource.render.TitleText.j003}
                <IconQuestion onClick={this.handleOpen} />
              </CustomFontPBold>
              <InputTelFull
                name="securityCode"
                placeholder={PaymentCardUpdateResource.render.Placeholder.j003}
                maxLength={4}
                value={securityCode}
                onChange={this.handleChange}
                autoComplete="cc-csc"
              />
            </Grid>
            <Grid item xs={12}>
              <ThreeDSecureDescription size="ss" color="black">
                {PaymentCardUpdateResource.render.ThreeDSecure.j001}
              </ThreeDSecureDescription>
            </Grid>
            <Grid item xs={12}>
              <InvisibleRecaptcha
                recaptchaTokenCallback={this.recaptchaTokenCallback}
                showError={this.props.showError}
              >
                {this.props.cardSeq ? (
                  <ButtonFullWidth type="button" id="update-credit-card">
                    {PaymentCardUpdateResource.render.Button.j001}
                  </ButtonFullWidth>
                ) : (
                  <ButtonFullWidth type="button" id="register-credit-card">
                    {PaymentCardUpdateResource.render.Button.j002}
                  </ButtonFullWidth>
                )}
              </InvisibleRecaptcha>
            </Grid>
          </Grid>
        </form>
        <PaymentDialog
          openDialog={this.state.openDialog}
          handleClose={this.handleClose}
        />
      </React.Fragment>
    );
  }
}

export default PaymentCardUpdate;
