import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { captureMessage, captureException, Severity } from "@sentry/browser";
import styled from "styled-components";

import AuthenticatedTemplate from "../../templates/AuthenticatedTemplate";
import { showSuccessDialog } from "../../organisms/global/SuccessDialog";
import StandbyLoadingBall from "../../atoms/StandbyLoadingBall";
import FontP from "../../atoms/fonts/FontP";

import graphqlClient from "../../../Utils/GraphQLClient";
import { Account, RegisterCreditCard } from "../../../Utils/GraphQLQueries";
import { useSessionStorage } from "../../../hooks/useBrowserStorage";
import { toastError } from "../../../Utils/Utility";
import { japaneseList } from "../../../Resources/japaneseList";
import Titles from "../../../Resources/Titles";
import { device } from "../../device";

interface RegisterCreditCardResponse {
  success: boolean;
  error?: any;
}

interface ReserveInput {
  hospitalId?: string;
  menuId?: string;
}
interface ReserveData {
  menu?: {
    requiredAddressRegister?: any;
  };
}
interface ReserveFlowData {
  reserveInput?: ReserveInput;
  reserveData?: ReserveData;
}

const UpdateResource = japaneseList.organisms.payment.PaymentCardUpdate;
const CallBackResource = japaneseList.pages.payment.RegisteringCallBackPage;

const RESERVE_DATA = "reserveData";
const RESERVE_INPUT = "reserveInput";
const CARD_SEQ = "cardSeq";

const CompleteModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #666;
  z-index: 90;
  touch-action: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  @media screen and ${device.tb} {
    left: 50%;
    width: 424px;
    margin-left: -212px;
  }
  @media screen and ${device.pc} {
    width: 424px;
  }
  & > * {
    width: 70%;
  }
`;

// リダイレクトを受けるページ
const RegisteringCallBackPage: React.FC = () => {
  const history = useHistory();
  const { get, remove } = useSessionStorage();
  const rawCardSeq = parseInt(get(CARD_SEQ) ?? "");
  const cardSeq = isNaN(rawCardSeq) ? undefined : rawCardSeq;

  const existsAddress = async (): Promise<boolean> => {
    const {
      data: { account },
    } = await graphqlClient.query({ query: Account });
    return Boolean(account?.address?.postalCode);
  };

  const registerCreditCard = async (
    accessId: string
  ): Promise<RegisterCreditCardResponse> => {
    try {
      const { data } = await graphqlClient.mutate({
        mutation: RegisterCreditCard,
        variables: {
          accessId,
          cardSeq,
        },
      });
      return { success: Boolean(data?.registerCreditCard) };
    } catch (error) {
      captureException(error);
      return { success: false, error };
    }
  };

  const getReserveFlowData = (): ReserveFlowData => {
    return {
      reserveInput: get<ReserveInput>(RESERVE_INPUT) ?? undefined,
      reserveData: get<ReserveData>(RESERVE_DATA) ?? undefined,
    };
  };
  const removeSessionStorage = () => {
    remove(RESERVE_INPUT);
    remove(RESERVE_DATA);
    remove(CARD_SEQ);
  };

  // 登録成功時の遷移処理
  const handleSuccessNavigation = async ({
    reserveInput,
    reserveData,
  }: ReserveFlowData): Promise<void> => {
    showSuccessDialog(CallBackResource.render.success);
    captureMessage("credit-card-register", Severity.Log);

    // 予約フロー以外の場合はカード情報ページに遷移
    const isReserveFlow = Boolean(reserveInput && reserveData);
    if (!isReserveFlow)
      return history.replace({ pathname: "/my-page/payment/card-info" });

    // 予約フローの場合は住所登録の要否で遷移先を分岐
    const addressRequired = reserveData?.menu?.requiredAddressRegister;
    const addressExists = await existsAddress();
    addressRequired && !addressExists
      ? history.replace({
          pathname: `/my-page/address/update`,
          state: {
            from: "reserve",
            reserveData,
            reserveInput,
          },
        })
      : history.replace({
          pathname: `/reserve/input/${reserveInput?.hospitalId}/${reserveInput?.menuId}`,
          state: { reserveData, reserveInput },
        });
  };

  // 登録失敗時の遷移処理
  const handleFailureNavigation = (
    { reserveInput, reserveData }: ReserveFlowData,
    error: any
  ): void => {
    captureException(error);
    toastError(UpdateResource.onGetToken.j001);

    // カード登録画面のパスが予約フローだと異なる
    const isReserveFlow = Boolean(reserveInput && reserveData);
    isReserveFlow
      ? history.replace({
          pathname: "/reserve/input-paymentcard",
          state: {
            reserveData,
            reserveInput,
          },
        })
      : history.replace({
          pathname: `/my-page/payment/card-info/update/${cardSeq ?? ""}`,
        });
  };

  const handleCreditCardRegistration = async (
    accessId: string
  ): Promise<void> => {
    const { success, error } = await registerCreditCard(accessId);
    const reserveFlowData = getReserveFlowData();
    removeSessionStorage();

    success
      ? await handleSuccessNavigation(reserveFlowData)
      : handleFailureNavigation(reserveFlowData, error);
  };

  // コールバック時のAccessIDでカード登録を行う
  useEffect(() => {
    const accessId = new URL(window.location.href).searchParams.get("AccessID");
    if (accessId) handleCreditCardRegistration(accessId);
  }, []);

  const main = (
    <CompleteModal>
      <FontP color="white" weight="bold" align="center">
        {CallBackResource.render.j001}
      </FontP>
      <StandbyLoadingBall color="fontInvert" />
    </CompleteModal>
  );
  return (
    <AuthenticatedTemplate
      title={Titles.cardRegistering}
      main={main}
      isLoading={false}
    />
  );
};
export default RegisteringCallBackPage;
